import {heightControl, contractNameDecomposer, displayCommodity} from '../main';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import $ from "jquery";
import moment from "moment";
// import * as fb from '@/firebase';
const axios = require('axios');

function removeCotPanel(chart) {
    // console.log("removeCotPanel() starting.");
    chart.series.removeIndex(chart.series.indexOf(chart.map.getKey("commercial-series"))).dispose();
    chart.series.removeIndex(chart.series.indexOf(chart.map.getKey("nonCommercial-series"))).dispose();
    chart.series.removeIndex(chart.series.indexOf(chart.map.getKey("smallTrader-series"))).dispose();

    chart.yAxes.removeIndex(chart.yAxes.indexOf(chart.map.getKey("cot-axis"))).dispose();
    heightControl("removing", chart);
}

function addCotPanel(chart, mainParsedData, generalForm, vm) {
    console.log("addCotPanel() starting.");
    let commercialSeries = chart.map.getKey("commercial");
    // console.log("commercialSeries=", commercialSeries);
    let formatString = "#,###.";

    let alreadyPresent = typeof commercialSeries !== 'undefined';
    console.log("alreadyPresent=", alreadyPresent);

    if (!alreadyPresent) {

        let commodity = contractNameDecomposer(generalForm.sampleContract[0]).commoditySymbol;
        // let storage = fb.storage;
        let url = "https://storage.googleapis.com/authentication-f0d7e.appspot.com/cot/" + commodity + ".txt";

        axios.get(url)
                .then(apiResponse => {
                    vm.$store.commit('decrementNumberOfAjaxCalls');
                    console.log("apiResponse=", apiResponse);
                    let cotData = apiResponse.data;
                    //  console.log("cotData=", cotData);
                    let lastCotDate = cotData[cotData.length - 1].date;
                    console.log("lastCotDate=", lastCotDate);

                    $("#no_cot_message").remove();
                    // let start = generalForm.chartParameters.start;
                    // console.log("start=", start);

                    let mainData = chart.map.getKey("main-series").data;
                    // console.log("mainData=", mainData);
                    let firstMainDataDate = mainData[0].date;
                    console.log("firstMainDataDate=", moment(firstMainDataDate).format("YYYYMMDD"));

                    let previousCotData = cotData.filter(x => moment(x.date, "YYYYMMDD").isBefore(moment(firstMainDataDate, "YYYYMMDD")))
                    // console.log("previousCotData=", previousCotData);
                    let previousBar = previousCotData[previousCotData.length - 1];
                    previousBar.commercial = previousBar.com;
                    previousBar.nonCommercial = previousBar.noncom;
                    previousBar.smallTrader = previousBar.small;

                    delete previousBar.com;
                    delete previousBar.noncom;
                    delete previousBar.small;
                    // console.log("previousBar=", previousBar);

                    mainData.forEach(function (bar) {
                        // console.log("bar=", JSON.parse(JSON.stringify(bar)));
                        // console.log("bar.date=", moment(bar.date).format("YYYYMMDD"));
                        let cotBar = cotData.find(x => x.date == moment(bar.date).format("YYYYMMDD"));
                        if (typeof cotBar !== 'undefined') {
                            // console.log("cotBar=", JSON.parse(JSON.stringify(cotBar)));
                            bar.commercial = cotBar.com;
                            bar.nonCommercial = cotBar.noncom;
                            bar.smallTrader = cotBar.small;
                            previousBar = bar;
                        } else {
                            // console.log("using previousBar. previousBar=", JSON.parse(JSON.stringify(previousBar)));
                            if (typeof previousBar !== 'undefined' && moment(bar.date).isBefore(moment(lastCotDate, "YYYYMMDD"))) {
                                bar.commercial = previousBar.commercial;
                                bar.nonCommercial = previousBar.nonCommercial;
                                bar.smallTrader = previousBar.smallTrader;
                            }
                        }
                    });
                    // console.log("mainData=", mainData);

                    mainParsedData.cot = mainData;
                    let axisPercentage = heightControl("adding", chart);

                    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
                    valueAxis.id = "cot-axis";
                    valueAxis.tooltip.disabled = true;
                    valueAxis.height = am4core.percent(axisPercentage);
                    valueAxis.zIndex = 3;
// this makes gap between panels
                    valueAxis.marginTop = 16;
                    valueAxis.renderer.baseGrid.disabled = true;
                    valueAxis.renderer.inside = true;
                    valueAxis.renderer.labels.template.verticalCenter = "bottom";
                    valueAxis.renderer.labels.template.padding(2, 2, 2, 2);
//valueAxis.renderer.maxLabelPosition = 0.95;
                    valueAxis.renderer.fontSize = "0.8em";
                    valueAxis.renderer.gridContainer.background.fillOpacity = 0.05;
                    valueAxis.title.text = "COT of " + displayCommodity(commodity, vm.$store.state.user.symbols);
                    valueAxis.title.fontWeight = "bold";
                    valueAxis.zoomable = false;

                    let commercialSeries = chart.series.push(new am4charts.LineSeries());
                    commercialSeries.id = "commercial-series";
                    commercialSeries.data = mainData;
                    commercialSeries.dataFields.valueY = "commercial";
                    commercialSeries.dataFields.dateX = "date";
                    commercialSeries.yAxis = valueAxis;
                    commercialSeries.name = "commercialSeries";
                    commercialSeries.strokeWidth = 1;
                    commercialSeries.stroke = "red";
                    commercialSeries.fillOpacity = 0.0;
                    commercialSeries.stacked = false;
                    commercialSeries.legendSettings.labelText = "[{color}]com: {valueY}";
                    commercialSeries.hiddenInLegend = true;
                    commercialSeries.tooltipText = generalForm.chartParameters.balloons ? "commercial: {valueY.value.formatNumber('" + formatString + "')}" : "";
                    commercialSeries.fill = "red";
                    commercialSeries.showOnInit = false;

                    let nonCommercialSeries = chart.series.push(new am4charts.LineSeries());
                    nonCommercialSeries.id = "nonCommercial-series";
                    nonCommercialSeries.data = mainData;
                    nonCommercialSeries.dataFields.valueY = "nonCommercial";
                    nonCommercialSeries.dataFields.dateX = "date";
                    nonCommercialSeries.yAxis = valueAxis;
                    nonCommercialSeries.name = "nonCommercialSeries";
                    nonCommercialSeries.strokeWidth = 1;
                    nonCommercialSeries.stroke = "blue";
                    nonCommercialSeries.fillOpacity = 0.0;
                    nonCommercialSeries.stacked = false;
                    nonCommercialSeries.legendSettings.labelText = "[{color}]non-com: {valueY}";
                    nonCommercialSeries.hiddenInLegend = true;
                    nonCommercialSeries.tooltipText = generalForm.chartParameters.balloons ? "non-commercial: {valueY.value.formatNumber('" + formatString + "')}" : "";
                    nonCommercialSeries.fill = "blue";
                    nonCommercialSeries.showOnInit = false;

                    let smallTraderSeries = chart.series.push(new am4charts.LineSeries());
                    smallTraderSeries.id = "smallTrader-series";
                    smallTraderSeries.data = mainData;
                    smallTraderSeries.dataFields.valueY = "smallTrader";
                    smallTraderSeries.dataFields.dateX = "date";
                    smallTraderSeries.yAxis = valueAxis;
                    smallTraderSeries.name = "smallTraderSeries";
                    smallTraderSeries.strokeWidth = 1;
                    smallTraderSeries.stroke = "black";
                    smallTraderSeries.stacked = false;
                    smallTraderSeries.tooltipText = generalForm.chartParameters.balloons ? "small trader: {valueY.value.formatNumber('" + formatString + "')}" : "";
                    smallTraderSeries.fill = "black";
                    smallTraderSeries.hiddenInLegend = true;
                    smallTraderSeries.legendSettings.labelText = "[{color}]sm trader: {valueY}";
                    smallTraderSeries.showOnInit = false;

                    valueAxis.events.once("startendchanged", () => {
                        //chart.zoomAxes(chart.yAxes, {start: 0, end: 1});
                        // console.log("valueAxis._minReal=", valueAxis._minReal);
                        //  console.log("valueAxis._maxReal=", valueAxis._maxReal);

                        valueAxis.zoomToValues(valueAxis._minReal, valueAxis._maxReal);

                        // let dateAxis = chart.map.getKey("date-axis");
                        // dateAxis.zoomToDates(0,1);

                    });
                })
                .catch(() => {
                    vm.$store.commit('decrementNumberOfAjaxCalls');
                    console.log("error");
                    $("#" + generalForm.moduleName + "-chartDiv").before("<span id='no_cot_message' style='font-size: 12px; color: red; text-align: left; padding: 2px'>There is no commitment of traders data for this commodity.</span>");
                });
        //  });

    }
}

function setCotTooltips(chart, balloons) {
    let formatString = "#,###.";

    let commercialSeries = chart.map.getKey("commercial-series");
    let nonCommercialSeries = chart.map.getKey("nonCommercial-series");
    let smallTraderSeries = chart.map.getKey("smallTrader-series");
    // console.log("rsiSeries=", series);
    if (typeof commercialSeries !== 'undefined') {
        commercialSeries.tooltipText = balloons ? "commercial: {valueY.value.formatNumber('" + formatString + "')}" : "";
        nonCommercialSeries.tooltipText = balloons ? "non-commercial: {valueY.value.formatNumber('" + formatString + "')}" : "";
        smallTraderSeries.tooltipText = balloons ? "small trader: {valueY.value.formatNumber('" + formatString + "')}" : "";
    }
}

export {addCotPanel, removeCotPanel, setCotTooltips};