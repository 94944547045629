<template>
    <div style="background-color: white;  padding: 0 1px 0 1px">

        <div style="color: red" v-if="$store.state[this.namespace].selected.length == 0">
            Please select.
        </div>
        <div class="selectDiv" style="width: 100%; border: 1px solid gray; border-radius: 3px; margin-left: 0px;">       

            <select v-model="selected" 
                    v-bind:size="listOptions.length" 
                    v-bind:multiple="true" class="list">
                <option style="text-align: center"
                        v-for="(option, i) in listOptions" :key="i" 
                        v-bind:value="option.value">
                    {{option.text}}
                </option>
            </select>
        </div>
    </div>
</template>

<script>
    import {listTickerOptions} from "../js/main";

    export default {
        mounted: function () {
           // console.log("contract-selector.vue mounted() starting.");
        },
        props: ['namespace'],
        computed: {
            listOptions() {
              //  console.log("listOptions() starting.")
                let generalForm = JSON.parse(JSON.stringify(this.$store.state[this.namespace]));
              //  console.log("generalForm=", generalForm);

                return listTickerOptions(generalForm, this.$store.state.user.symbols);
            },
            selected: {
                get() {
                    return this.$store.state[this.namespace].selected;
                },
                set(selected) {
                   // console.log("selected set() starting.");
                    this.$store.commit(this.namespace + '/setSelected', selected);
                }
            },
            selectedTickers(){
              return this.selected.map(value => this.listOptions.find(x => x.value === value).text);
            }
        }
    }
</script>

<style>
    .selectDiv {
        display:inline-block;
        vertical-align:top; overflow:hidden;
        border:solid grey 0px; 
        background-color: white;
        margin: -3px 6px 4px 6px;
    }

    .list {
        padding: 5px 13px 6px 22px;
        margin:-4px -30px -11px -20px;
        border:solid grey 0px; 
        background-color: white;
    }

    .selectTitle{
        margin:3px 2px -4px 2px;
        font-weight: bold;
        font-size: 12px;
        height: 100%;
        background-color: white
    }

    .contractAligner{
        margin: 0px 0px 3px 0px;
    }
</style>
