import $ from "jquery";
import { spreadDigits } from "../main";
import * as am4charts from "@amcharts/amcharts4/charts";
import moment from "moment";

function removeSeasonal(years, chart) {
    // console.log("removeSeasonal() starting.");
    if (years.length > 0) {
        // console.log("removing " + years + "-year seasonal.");
        let series = chart.map.getKey(years + "-y");
        if (chart.series.indexOf(series) > -1) {
            chart.series.removeIndex(chart.series.indexOf(series)).dispose(); //series.hide();  
        }
    }
}

function addSeasonals(seasonals, chart, generalForm, seasonalDataArray, seasonalColorOptions, chartObject) {
    // console.log("addSeasonals() starting. seasonals=", seasonals);
    //console.log("Object.keys(chart)=", Object.keys(chart));
    if (Object.keys(chart).length > 0) {
        // console.log("seasonalDataArray=", seasonalDataArray);
        //  console.log("generalForm=", generalForm);
        // console.log("seasonalColorOptions=", seasonalColorOptions);
        let digits = spreadDigits(generalForm.selected[0], generalForm.instrument);
        // console.log("digits=", digits);
        let formatString = "#." + "0".repeat(digits);
        let seasonalColors = ["orange", "darksalmon", "crimson", "brown", "black"];

        if (typeof seasonalDataArray !== 'undefined') {
            let selected = generalForm.selected[0];
            //  console.log("selected=", selected);

            let digits = 1 + spreadDigits(selected);
            // console.log("digits=", digits);
            // console.log("chart=", chart);
            for (var i = 0; i < seasonals.length; i++) {
                let id = seasonals[i] + "-y";
                removeSeasonal(seasonals[i], chart);

                var series = chart.series.push(new am4charts.LineSeries());
                series.id = id;
                series.dataFields.valueY = seasonals[i];
                series.dataFields.dateX = "date";
                series.name = seasonals[i] + "-year seasonal:";
                series.strokeWidth = 1;

                let colorOption = seasonalColorOptions.find(x => x.value == seasonals[i]);

                series.stroke = typeof colorOption === 'undefined' ? seasonalColors[i] : colorOption.color;
                series.fill = series.stroke;
                series.fillOpacity = 0.0;
                series.stacked = false;
                series.legendSettings.labelText = "[{color}] {id}: {valueY}";
                series.tooltipText = generalForm.chartParameters.balloons ? seasonals[i] + "-y: {valueY.value.formatNumber('" + formatString + "')}" : "";
                series.data = seasonalDataArray.map(x => {
                    return {
                        date: moment(x.date, "YYYYMMDD").format("YYYY-MM-DD"),
                        [seasonals[i]]: parseFloat(x[seasonals[i]]).toFixed(digits)
                    };
                }).filter(x => !isNaN(x[seasonals[i]]));
                // console.log("series.data =", series.data);
            }

            let mainAxis = chart.map.getKey("main-axis");
            series.events.once("datavalidated", () => {
                console.log("datavalidated");
                // console.log("chart=",chart);
                //chart.zoomAxes(chart.yAxes, {start: 0, end: 1});
                // console.log("mainAxis._minReal=", mainAxis._minReal);
                // console.log("mainAxis._maxReal=", mainAxis._maxReal);
                mainAxis.zoomToValues(mainAxis._minReal, mainAxis._maxReal);
                // let dateAxis = chart.map.getKey("date-axis");
                // dateAxis.zoomToDates(0, 1);
                chartObject.initialZoom();

            });
        }
    }
}

function getSeasonalData(newSeasonals, chart, generalForm, seasonalDataArray, seasonalColorOptions, chartObject) {
    // console.log("getSeasonalData() starting. newSeasonals=", newSeasonals);

    if (newSeasonals.length > 0) {
        chartObject.$store.commit('incrementNumberOfAjaxCalls');
        generalForm.seasonals = newSeasonals;
        delete generalForm.browserSideOnly;
        delete generalForm.playback;
        let json = JSON.stringify(generalForm, null, 2);

        $.ajax({
            url: chartObject.$store.state.siteData.baseUrl + chartObject.$store.state.siteData.contextName + "/StandardSeasonalsServlet/",
            type: "POST",
            data: {"requestJson": json},
            success: function (data) {
                chartObject.$store.commit('decrementNumberOfAjaxCalls');
                // console.log("data=", data);
                let parsedData = JSON.parse(data);
                // console.log("seasonal parsedData=", parsedData);

                if (typeof parsedData.dataset !== 'undefined') {
                    let returnedSeasonals = parsedData.dataset.map(x => parseInt(x.split('-')[0]));
                    // console.log("returnedSeasonals=", returnedSeasonals);

                    // let digits = 1 + spreadDigits(generalForm.selected[0]);
                    // console.log("digits=", digits);

                    let additionalSeasonalDataArray = Object.keys(parsedData.values).map(date => ({date: parseInt(date), ...parsedData.values[date]}));
                    // console.log("additionalSeasonalDataArray=", [...additionalSeasonalDataArray]);
                    let concatenatedSeries = [...seasonalDataArray, ...additionalSeasonalDataArray];
                    // console.log("concatenatedSeries=", concatenatedSeries);
                    let uniqueDates = [...new Set(concatenatedSeries.map(x => x.date))].sort();
                    // console.log("uniqueDates=", uniqueDates);

                    seasonalDataArray = uniqueDates.map(date => (
                                concatenatedSeries.filter(x => x.date == date)
                                .reduce((combinedBar, bar) => ({...combinedBar, ...bar}), {})
                                ));
                    // console.log("that.seasonalDataArray=", that.seasonalDataArray);

                    addSeasonals(returnedSeasonals, chart, generalForm, seasonalDataArray, seasonalColorOptions, chartObject);
                }
            },
            fail: function (data) {
                console.log("ajax call failed.");
                chartObject.$store.commit('decrementNumberOfAjaxCalls');
                $("#progress").hide();
                $("#button").show();
                console.log(data);
            }
        });
    }
}

function setSeasonalTooltips(chart, balloons, generalForm) {
    let digits = spreadDigits(generalForm.selected[0], generalForm.instrument);
    // console.log("digits=", digits);
    let formatString = "#." + "0".repeat(digits);

    let seasonalSeries = chart.series.values.filter(series => series.id.includes("-y"));
    console.log("seasonalSeries=", seasonalSeries);

    seasonalSeries.forEach(series => {
        series.tooltipText = balloons ? series.id + ": {valueY.value.formatNumber('" + formatString + "')}" : "";
    });
}

export { getSeasonalData, addSeasonals, removeSeasonal, setSeasonalTooltips };