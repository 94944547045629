<template>
    <table border="0" style="border-right: 1px solid lightgray; font-weight: normal;">
        <tr>
            <td style="vertical-align: top; margin: 0px;">
        <plotly-heatmap v-bind:namespace="namespace"></plotly-heatmap>
        </td>
        <td style="vertical-align: top; padding-bottom: 0px; width: 800px">

            <div style="margin: 0px 0px 0px 0px; border-right: 0px solid darkgray; border-top: 0px solid darkgray;">
                <basic-chart-output v-bind:namespace="namespace"></basic-chart-output>  
            </div>
            <div style="margin: 0 0px 0px 0px; border: 0px solid darkgray;">
                <calculator-output v-bind:namespace="namespace"
                                   v-bind:width="'740px'"></calculator-output>  
            </div>  

        </td>
        </tr>
    </table>
</template>

<script>
    // import moment from 'moment';
    import $ from "jquery";
    import basicChartOutput from '@/components/programs/BasicChart/basic-chart-output';
    import calculatorOutput from '@/components/programs/Calculator/calculator-output';
    import plotlyHeatmap from '@/components/plotly-heatmap';
    import {orderContracts, contractNameDecomposer, getCommoditiesArray} from '@/js/main';
    import {decompress} from "@/js/jsonhNew";
    import * as fb from '@/firebase';
    const axios = require('axios');

    export default {
        components: {
            calculatorOutput, basicChartOutput, plotlyHeatmap
        },
        vectorMap: {}, // <-- non-reactive property
        rawData: {}, // <-- non-reactive property
        mounted() {
            // console.log("trade-maps-output.vue mounted() starting. this.ticker=", this.ticker);
            this.$store.commit(this.namespace + '/setTabTitle', "map: " + this.displayTicker);
            // console.log("this.ticker=", this.ticker);
          //  this.$store.commit(this.namespace + '/setShowTradingPeriod', true);

            if (this.activeModuleName === this.namespace && !this.initialized && this.ticker !== null) {
                this.getMapData();
            }
        },
        props: ['namespace'],
        beforeDestroy() {
            console.log("trade-maps-output.vue beforeDestroy() starting.");
            // removeHeatmap(this);
        },
        data: function () {
            return{
                initialized: false
            }
        },
        computed: {
            open() {
                return this.$store.state[this.namespace].open;
            },
            close() {
                return this.$store.state[this.namespace].close;
            },
            ticker() {
                return this.$store.getters[this.namespace + "/ticker"];
            },
            displayTicker() {
                return this.$store.getters[this.namespace + "/displayTicker"];
            },
            activeModuleName() {
                let activeModuleName = this.$store.getters['activeModuleName'];
                // console.log("activeModuleName=", activeModuleName);
                return activeModuleName;
            },
            parameterToMap() {
                return this.$store.state[this.namespace].chartParameters.parameterToMap;
            },
            firebaseStorageDirectory() {
                return this.$store.state.siteData.firebaseStorageDirectory;
            },
            buySell() {
                return this.$store.state[this.namespace].buySell;
            },
            direction(){
                 let isBefore = true;
                 let generalForm = JSON.parse(JSON.stringify(this.$store.state[this.namespace]));
                 let contract = generalForm.sampleContract.slice(0, generalForm.legs);
                 if (generalForm.legs > 1) {
                        let newOrder = orderContracts(contract[0], contract[1])
                        isBefore = (contract[0] == newOrder[0]);
                    }
                    // console.log("isBefore=" + isBefore);
                    if (!isBefore) {
                        contract = contract.reverse();
                    }
                    // console.log("contract=", contract);

                    let directionCoefficient = 1;
                    if ((generalForm.legs == 1 && generalForm.p[0] == -1) || (generalForm.legs == 2 && generalForm.p[0] == 1 && !isBefore) || 
                        (generalForm.legs == 2 && generalForm.p[0] == -1 && isBefore)) {
                        directionCoefficient = -1;
                    }
                    let buySellCoefficient = this.buySell === "buy" ? 1 : -1;

                    let direction = directionCoefficient * buySellCoefficient === 1 ? "up" : "down"; 
                    console.log("direction=" + direction);
                    return direction;
            },
            symbols(){
                return this.$store.state.user.symbols;
            }
        },
        watch: {
             symbols(symbols){
                console.log("watch symbols=", symbols);
                this.getCharts();
            },
            ticker: function (newTicker, oldTicker) {
                console.log("watch: newTicker=", newTicker, " oldTicker=", oldTicker, " namespace=", this.namespace);
                this.$children[0].removeHeatmap();
                this.$store.commit(this.namespace + '/setTabTitle', "map: " + this.displayTicker);
                if (newTicker !== null && newTicker !== oldTicker) {
                    //removeStatChart();
                    this.getMapData();
                } else {
                    $('#' + this.namespace + '-plotlyHeatMapDiv').html("");
                }
            },
            activeModuleName: function (activeModuleName) {
                // console.log("activeModuleName=", activeModuleName, " namespace=", this.namespace, " this.initialized=", this.initialized);
                if (activeModuleName === this.namespace && !this.initialized && this.ticker !== null) {
                    this.$children[0].removeHeatmap();
                    this.getMapData();
                }
            },
            parameterToMap() {
                // console.log("watching parameterToMap");
                if (this.activeModuleName === this.namespace) {
                  this.$children[0].runPlotly(this.$options.vectorMap, this.parameterToMap);
                }
            },
            buySell(){
                console.log("watching buySell");
                this.getCharts();
            },
            displayTicker(){
                this.$store.commit(this.namespace + '/setTabTitle', "map: " + this.displayTicker);
            }
        },
        methods: {
            getCharts(){
                this.$options.vectorMap = decompress(JSON.stringify(this.$options.rawData));
                console.log("this.$options.vectorMap=", JSON.parse(JSON.stringify(this.$options.vectorMap)));
                                           
                                           let openStartDate = Object.keys(this.$options.vectorMap)[0];
                                            // console.log("openStartDate=", openStartDate);

                                            if (typeof openStartDate !== 'undefined') {
                                                let startingCloseDates = Object.keys(this.$options.vectorMap[openStartDate]);
                                                // console.log("startingCloseDates=", startingCloseDates);

                                                if (typeof startingCloseDates !== 'undefined' && startingCloseDates.length > 0) {
                                                    // console.log("that.$children[0]=", that.$children[0]);
                                                    let commodity = this.$store.getters[this.namespace + "/c"][0];
                                                    // console.log("commodity=", commodity);

                                                    let unitMove = getCommoditiesArray().find(x => x.symbol === commodity).unitMove;
                                                    //console.log("unitMove=", unitMove);

                                                    let newTradeObject = {};
                                                    let upValue;
                                                    let downValue;
                                                    if (this.direction === "down") {
                                                        for (let open in this.$options.vectorMap) {
                                                            let openVector = this.$options.vectorMap[open];
                                                            // console.log("open=", open + ':', openVector);
                                                            for (let close in openVector) {
                                                                //  console.log(open + ':', closeVector[open]);
                                                                upValue = openVector[close];
                                                                downValue = Object.assign({}, openVector[close])

                                                                downValue["close"] = close;
                                                                downValue["avgAppd"] = -(unitMove * upValue.avgChange / upValue.avgDays).toFixed(2);
                                                                downValue["avgChange"] = -(unitMove * upValue.avgChange).toFixed(2);
                                                                downValue["avgMax"] = -upValue.avgMin;
                                                                downValue["bestMax"] = -upValue.worstMin;
                                                                downValue["avgMin"] = -upValue.avgMax;
                                                                downValue["worstMin"] = -upValue.bestMax;
                                                                downValue["rrr"] = -upValue.avgMin / upValue.avgMax;
                                                                downValue["percentUp"] = upValue.percentDown;
                                                                downValue["percentDown"] = upValue.percentUp;
                                                                downValue["rrr"] = -(upValue.avgMin / upValue.avgMax).toFixed(3);
                                                                openVector[close] = downValue;
                                                            }
                                                            newTradeObject[open] = openVector;
                                                        }
                                                        // console.log("newTradeObject=", newTradeObject);
                                                        this.$options.vectorMap = newTradeObject;
                                                    } else {
                                                        for (let open in this.$options.vectorMap) {
                                                            let openVector = this.$options.vectorMap[open];
                                                            // console.log("open=", open + ':', openVector);
                                                            for (let close in openVector) {
                                                                // console.log(close + ':', openVector[close]);
                                                                upValue = openVector[close];

                                                                upValue["open"] = open;
                                                                upValue["avgAppd"] = (unitMove * upValue.avgChange / upValue.avgDays).toFixed(2);
                                                                upValue["avgChange"] = (unitMove * upValue.avgChange).toFixed(2);
                                                                upValue["rrr"] = -(upValue.avgMax / upValue.avgMin).toFixed(3);
                                                                openVector[close] = upValue;
                                                            }
                                                            if (Object.keys(openVector).length !== 0) {
                                                                newTradeObject[open] = openVector;
                                                            }
                                                        }
                                                        //  console.log("newTradeObject=", newTradeObject);
                                                        this.$options.vectorMap = newTradeObject;
                                                    }

                                                    this.$children[0].runPlotly(this.$options.vectorMap, this.parameterToMap);
                                                } else {
                                                    $('#' + this.namespace + '-plotlyHeatMapDiv').html("<H4 style='color: red; text-align: left; margin: 20px 0 0 10px'>\n\
                                                    No more trades for this years spread. Next years' map will be created after the current spread expires.</H3>");
                                                }
                                            } else {
                                                $('#' + this.namespace + '-plotlyHeatMapDiv').html("<H4 style='color: red; text-align: left; margin: 20px 0 0 10px'>\n\
                                                    No more trades for this years spread. Next years' map will be created after the current spread expires.</H3>");
                                            }
            },
            getMapData() {
                //  console.log("getMapData() starting.");
                this.initialized = true;
                this.$store.commit('incrementNumberOfAjaxCalls');
                $('#' + this.namespace + '-plotlyHeatMapDiv').html("");

                let generalForm = JSON.parse(JSON.stringify(this.$store.state[this.namespace]));
                generalForm.generator = "TradeMapGenerator";
                delete generalForm.browserSideOnly;

                let contract = generalForm.sampleContract.slice(0, generalForm.legs).sort();
                console.log("contract=" + contract);
                if (generalForm.legs == 1 || contract[0] !== contract[1]) {
                   
                    let key = "";
                    let cND;
                    for (let i = 0; i < generalForm.legs; i++) {
                        cND = contractNameDecomposer(contract[i]);
                        key = key + cND.commoditySymbol + "-" + cND.monthSymbol + ":";
                    }
                    key = key.substring(0, key.length - 1);
                    let S3key = cND.commoditySymbol + "/" + key;
                    //  console.log("S3key=" + S3key);

                    let storage = fb.storage;
                    let path = this.firebaseStorageDirectory + "/" + S3key;
                    let pathReference = storage.ref(path);
                    console.log("pathReference=", pathReference._delegate.fullPath);

                    pathReference.getDownloadURL()
                            .then((url) => {
                                // console.log("url=", url);
                                axios.get(url)
                                        .then(apiResponse => {
                                            this.$store.commit('decrementNumberOfAjaxCalls');
                                            console.log("apiResponse=", apiResponse);
                                            this.$options.rawData = apiResponse.data;          
                                            this.getCharts();
                                        });
                            })
                            .catch(() => {
                                $('#' + this.namespace + '-plotlyHeatMapDiv').html("<H4 style='color: red; text-align: left; margin: 20px 0 0 10px'>\n\
                                                    No map data.</H3>");
                            });


                    /*      success: function (data) {
                     //  console.log("data=", data);
                     generalForm.title = "title"; //S3key;
                     try {
                     if (data !== "[]") {
                     
                     // console.log("that.$children[0]=", that.$children[0]);
                     that.$children[0].runPlotly(vectorMap, that.parameterToMap);
                     } else {
                     console.log("No open spreads.");
                     
                     /* $('#' + that.namespace + '-plotlyHeatMapDiv').html("<H4 style='color: red; text-align: left; margin-left:10px'>No open " +
                     (generalForm.legs == 1 ? "contracts" : "spreads") + ".</H3>"); 
                     }
                     } catch (error) {
                     console.log("error=", error);
                     
                     // $('#chartDiv').html("<H4 style='color: red; text-align: left; margin-left:10px'>Not enough data.</H3>");
                     //  updateOtherCharts();
                     
                     }
                     },
                     error: function () {
                     //  $('#plotlyHeatMapDiv').html("<H3 style='color: red; text-align: left; margin-left:10px'>Error.</H3>");
                     $('#' + that.namespace + '-plotlyHeatMapDiv').html("<H4 style='color: red; text-align: left; margin-left:10px'>No open " +
                     (generalForm.legs == 1 ? "contracts" : "spreads") + ".</H3>");
                     }
                     });*/
                } else {
                    this.$store.commit('decrementNumberOfAjaxCalls');
                    $('#' + this.namespace + '-plotlyHeatMapDiv').html("<div style='white-space: nowrap; color: red; font-size: 14px; padding:5px; font-weight: normal'>" +
                            "Nothing to map." + "</div>");
                }
            }
        }
    };

</script>
