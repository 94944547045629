<template>
  <span v-if="numberOfAjaxCalls > 0">

    <span v-if="$store.state.user.role === 'superadmin'">
      api calls: {{numberOfAjaxCalls}}
    </span>

      <b-spinner
        style="width: 1.5rem; height: 1.5rem; margin: -2px 6px 0px 3px"
        variant="primary"
        label="Spinner"
      ></b-spinner>
    </span>
</template>

<script>
module.exports = {
  computed: {
    numberOfAjaxCalls() {
      return this.$store.state.numberOfAjaxCalls;
    }
  }
};
</script>
