<template>
    <div id="subapp" style="background-color: white; font-weight: normal">
        <playback
            v-if="$store.state[namespace].showPlaybackControl"
            v-bind:namespace="namespace"
            v-bind:parsedData="parsedData"
            v-bind:chart="$options.chart"
            style="width: 100%; margin: 0px; padding: 0px"
            ></playback>

        <div
            v-bind:id="divName"
            v-bind:style="styleObject"
            style="border: 0px solid gray; text-align: center; margin: 0px"
            class="resize-basic-chart"
            v-on:click="clickHandler"
            ></div>
    </div>
</template>

<style>
    #subapp {
        display: flex;
        justify-content: left;
        align-items: left;
        flex-wrap: wrap;
    }

    .resize-basic-chart {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        overflow: hidden;
        resize: both;
        height: 700px;
    }
</style>

<script>
    import * as am4core from "@amcharts/amcharts4/core";
    import * as am4charts from "@amcharts/amcharts4/charts";
    import * as am4plugins_annotation from "@amcharts/amcharts4/plugins/annotation";
    import am4lang_en_US from "@amcharts/amcharts4/lang/en_US";
    import am4lang_it_IT from "@amcharts/amcharts4/lang/it_IT";
    import am4lang_es_ES from "@amcharts/amcharts4/lang/es_ES";
    import am4lang_de_DE from "@amcharts/amcharts4/lang/de_DE";

    import $ from "jquery";
    import moment from "moment";

    import {decode, getSpreadExpiration, spreadDigits, spreadTitle} from "@/js/main";
    import {addExpirationGuide} from "@/js/am4charts-plugins/am4charts.expiration-guide.js";
    import {addRsiPanel, removeRsiPanel, setRsiTooltips} from "@/js/am4charts-plugins/rsi";
    import {addVolume, removeVolume, setVolumeTooltips} from "@/js/am4charts-plugins/volume";
    import {addVolumeProfileIndicator, removeVolumeProfile, updateVolumeProfile, setVolumeProfileTooltips} from "@/js/am4charts-plugins/volumeProfile";
    import {changePeriod} from "@/js/am4charts-plugins/periodSelector";
    import {addBullets, removeBullets} from "@/js/am4charts-plugins/bullets";
    import {addLevels, removeLevels} from "@/js/am4charts-plugins/levels";
    import {getSeasonalData, removeSeasonal, setSeasonalTooltips} from "@/js/am4charts-plugins/seasonals";
    import {addAtrPanel, removeAtrPanel, setAtrTooltips} from "@/js/am4charts-plugins/atr";
    import {addCciPanel, removeCciPanel, setCciTooltips} from "@/js/am4charts-plugins/cci";
    import {addMacdPanel, removeMacdPanel, setMacdTooltips} from "@/js/am4charts-plugins/macd";
    import {addBollingerBands, removeBollinger} from "@/js/am4charts-plugins/bollinger";
    import {showTradingPeriodIndicator, hideTradingPeriod} from "@/js/am4charts-plugins/tradingPeriod";
    import {addCotPanel, removeCotPanel, setCotTooltips} from "@/js/am4charts-plugins/cot";
    import {addSma, removeSma} from "@/js/am4charts-plugins/sma";
    import {addEma, removeEma} from "@/js/am4charts-plugins/ema";

    import profitLoss from "../mixins/profitLoss";
    import playback from "@/components/playback.vue";

    import _ from 'lodash';

    export default {
        components: {
            playback
        },
        chart: {}, // <-- non-reactive property
        mixins: [profitLoss],
        created: function () {
            // console.log("this.$data=", this.$data);
        },
        mounted() {
            //  console.log("am4charts-basic-chart.vue mounted.");
            // console.log("this.$options.chart=", this.$options.chart);
            this.$root.$on("change-period", this.doChangePeriod);
        },
        beforeDestroy() {
            //  this.$options.chart.dispose && this.$options.chart.dispose(); //Not sure if this works.
            if (this.$options.chart.dispose) {
                //  console.log("disposing chart.");
                this.$options.chart.dispose();
            }
            this.$root.$off("change-period", this.doChangePeriod);
        },
        props: {
            divName: {
                type: String,
                default: "chartDiv",
            },
            namespace: {
                type: String,
                default: "",
            },
            parsedData: {
                type: Object,
            },
        },
        data: function () {
            return {
                checkingDate: "",
                expiration: "",
                //  chart: {},
                chartData: [],
                seasonalDataArray: [],
            };
        },
        computed: {
            styleObject() {
                return this.$store.state[this.namespace].program === "TradeMaps"
                        ? {height: "374px"}
                : {};
            },
            stockGraphType() {
                return this.$store.state[this.namespace].stockGraphType;
            },
            addRSIPanel() {
                return this.$store.state[this.namespace].addRSIPanel;
            },
            rsiPeriod() {
                return this.$store.state[this.namespace].rsiPeriod;
            },
            addCCIPanel() {
                return this.$store.state[this.namespace].addCCIPanel;
            },
            cciPeriod() {
                return this.$store.state[this.namespace].cciPeriod;
            },
            cciSource() {
                return this.$store.state[this.namespace].cciSource;
            },
            addMACDPanel() {
                return this.$store.state[this.namespace].addMACDPanel;
            },
            addBollinger() {
                return this.$store.state[this.namespace].addBollinger;
            },
            bollingerPeriod() {
                return this.$store.state[this.namespace].bollingerPeriod;
            },
            addSMA() {
                return this.$store.state[this.namespace].addSMA;
            },
            smaLength() {
                return this.$store.state[this.namespace].smaLength;
            },
            addEMA() {
                return this.$store.state[this.namespace].addEMA;
            },
            emaLength() {
                return this.$store.state[this.namespace].emaLength;
            },
            addVolumePanel() {
                return this.$store.state[this.namespace].addVolumePanel;
            },
            addVolumeProfile() {
                return this.$store.state[this.namespace].addVolumeProfile;
            },
            volumeProfileColumns() {
                return this.$store.state[this.namespace].volumeProfileColumns;
            },
            addATRPanel() {
                return this.$store.state[this.namespace].addATRPanel;
            },
            showCOTPanel() {
                return this.$store.state[this.namespace].addCOTPanel;
            },
            loading() {
                return this.$store.state[this.namespace].loading;
            },
            skipEmptyPeriods() {
                return this.$store.state[this.namespace].chartParameters.skipEmptyPeriods;
            },
            balloons() {
                return this.$store.state[this.namespace].chartParameters.balloons;
            },
            truncate() {
                return this.$store.state[this.namespace].truncate;
            },
            showBullets() {
                return this.$store.state[this.namespace].showBullets;
            },
            showLevels() {
                return this.$store.state[this.namespace].addHorizontalLine;
            },
            levels: {
                get() {
                    //  console.log("this.$store.state[this.namespace].chartParameters=", this.$store.state[this.namespace].chartParameters);
                    return this.$store.state[this.namespace].chartParameters.levels;
                },
                set(level) {
                    console.log("levels set(). level=", level);
                }
            },
            availableSeasonals() {
                return this.$store.getters[this.namespace + "/availableSeasonals"];
            },
            showTradingPeriod() {
                return this.$store.state[this.namespace].showTradingPeriod;
            },
            open() {
                return this.$store.state[this.namespace].open;
            },
            close() {
                return this.$store.state[this.namespace].close;
            },
            locale() {
                return this.$store.state.settings.locale;
            },
            showPlaybackControl() {
                return this.$store.state[this.namespace].showPlaybackControl;
            },
            horizontalScrollbar() {
                return this.$store.state[this.namespace].chartParameters.hiddenFeatures.horizontalScrollbar;
            },
            buySell() {
                return this.$store.state[this.namespace].buySell;
            },
            symbols() {
                return this.$store.state.user.symbols;
            }
        },
        watch: {
            symbols(symbols) {
                console.log("watch symbols=", symbols);
                let generalForm = JSON.parse(JSON.stringify(this.$store.state[this.namespace]));
                this.createChart(generalForm, this.divName);
            },
            parsedData: function (parsedData) {
                // console.log("watch parsedData = ", JSON.parse(JSON.stringify(parsedData)));
                let generalForm = JSON.parse(JSON.stringify(this.$store.state[this.namespace]));
                if (parsedData !== null) {
                    this.createChart(generalForm, this.divName);
                } else {
                    if (this.$options.chart.dispose) {
                        //  console.log("disposing chart.");
                        this.$options.chart.dispose();
                    }
                }
            },
            loading: function (loading) {
                // console.log("loading watch!");
                // console.log("this.divName=", this.divName);
                if (loading) {
                    // document.getElementById(this.divName).innerHTML = "";
                }
            },
            stockGraphType: function () {
                // console.log("watching stockGraphType");
                let generalForm = JSON.parse(
                        JSON.stringify(this.$store.state[this.namespace])
                        );
                //console.log("generalForm=", generalForm);
                this.createChart(generalForm, this.divName);
            },
            skipEmptyPeriods: function () {
                let dateAxis = this.$options.chart.map.getKey("date-axis");
                dateAxis.skipEmptyPeriods = this.$store.state[this.namespace].chartParameters.skipEmptyPeriods;
            },
            balloons: function (balloons) {
                let series = this.$options.chart.map.getKey("main-series");
                // console.log("series=", series);
                let generalForm = JSON.parse(JSON.stringify(this.$store.state[this.namespace]));

                let tooltipText = "";
                if (generalForm.stockGraphType === "line") {
                    tooltipText = balloons ? "close: {valueY.value}" : "";
                } else {
                    tooltipText = balloons
                            ? "open: {openValueY.value}\nlow: {lowValueY.value}\nhigh: {highValueY.value}\nclose: {valueY.value}"
                            : "";
                }
                series.tooltipText = tooltipText;

                setRsiTooltips(this.$options.chart, balloons);
                setVolumeTooltips(this.$options.chart, balloons);
                setVolumeProfileTooltips(this.$options.chart, balloons);
                setCciTooltips(this.$options.chart, balloons, generalForm);
                setMacdTooltips(this.$options.chart, balloons, generalForm);
                setAtrTooltips(this.$options.chart, balloons, generalForm);
                setCotTooltips(this.$options.chart, balloons);
                setSeasonalTooltips(this.$options.chart, balloons, generalForm);
            },
            /*  truncate() {
             // console.log("watching truncate");
             //this.truncateData();
             let generalForm = JSON.parse(JSON.stringify(this.$store.state[this.namespace]));
             this.createChart(generalForm, this.divName);
             },*/
            addRSIPanel(addRSIPanel) {
                // console.log("watching addRSIPanel=", addRSIPanel);
                let generalForm = JSON.parse(JSON.stringify(this.$store.state[this.namespace]));
                addRSIPanel ? addRsiPanel(this.$options.chart, this.rsiPeriod, generalForm) : removeRsiPanel(this.$options.chart);
            },
            rsiPeriod() {
                // console.log("watching rsiPeriod=", rsiPeriod);
                let generalForm = JSON.parse(JSON.stringify(this.$store.state[this.namespace]));
                addRsiPanel(this.$options.chart, this.rsiPeriod, generalForm);
            },
            addVolumePanel: function (addVolumePanel) {
                console.log("watch addVolumePanel = ", addVolumePanel);
                let generalForm = JSON.parse(JSON.stringify(this.$store.state[this.namespace]));
                addVolumePanel ? addVolume(this.$options.chart, generalForm) : removeVolume(this.$options.chart);
            },
            addVolumeProfile: function () {
                // console.log("watch addVolumeProfile=", addVolumeProfile);
                let generalForm = JSON.parse(JSON.stringify(this.$store.state[this.namespace]));
                if ((generalForm.legs == 1 || (["BasicCharts", "Playback"].includes(generalForm.program) && generalForm.dataSource !== "eod")) && generalForm.addVolumeProfile) {
                    addVolumeProfileIndicator(this.$options.chart, generalForm);
                } else {
                    removeVolumeProfile(this.$options.chart);
                }
            },
            volumeProfileColumns: function () {
                // console.log("watch volumeProfileColumns=", volumeProfileColumns);
                let generalForm = JSON.parse(JSON.stringify(this.$store.state[this.namespace]));
                updateVolumeProfile(this.$options.chart, generalForm);
            },
            showBullets: function (showBullets) {
                // console.log("watch showBullets = ", showBullets);
                let generalForm = JSON.parse(JSON.stringify(this.$store.state[this.namespace]));
                showBullets ? addBullets(this.$options.chart, generalForm) : removeBullets(this.$options.chart);
            },
            showLevels: function (showLevels) {
                // console.log("watch showLevels = ", showLevels);
                if (Object.keys(this.$options.chart).length > 0) {
                    let generalForm = JSON.parse(JSON.stringify(this.$store.state[this.namespace]));
                    showLevels && this.showPlaybackControl ? addLevels(this.$options.chart, generalForm) : removeLevels(this.$options.chart);
                }
            },
            showPlaybackControl: function (showPlaybackControl) {
                console.log("watch showPlaybackControl=", showPlaybackControl);
                let generalForm = JSON.parse(JSON.stringify(this.$store.state[this.namespace]));
                this.showLevels && showPlaybackControl ? addLevels(this.$options.chart, generalForm) : removeLevels(this.$options.chart);
            },

            /*  levels: {
             deep: true,
             
             handler() {
             // console.log("watch levels. this.activeModuleName=", this.activeModuleName, " this.namespace=", this.namespace, " this.initialized=", this.initialized);
             if (this.showLevels && Object.keys(this.$options.chart).length > 0) {
             let generalForm = JSON.parse(JSON.stringify(this.$store.state[this.namespace]));
             addLevels(this.$options.chart, generalForm);
             }
             }
             },*/
            availableSeasonals: function (newAvailableSeasonals, oldAvailableSeasonals) {
                // console.log("watch newAvailableSeasonals=", newAvailableSeasonals.slice(0), " oldAvailableSeasonals=", oldAvailableSeasonals.slice(0), " namespace=", this.namespace);
                let seasonalsToRemove = oldAvailableSeasonals.filter(x => !this.availableSeasonals.includes(x));
                // console.log("seasonalsToRemove =", seasonalsToRemove);
                removeSeasonal(seasonalsToRemove, this.$options.chart);

                if (newAvailableSeasonals.length > oldAvailableSeasonals.length) {
                    let seasonalToAdd = newAvailableSeasonals.find((x) => !oldAvailableSeasonals.includes(x));
                    console.log("seasonalToAdd=", seasonalToAdd);
                    let seasonalColorOptions = this.$store.getters[this.namespace + "/seasonalOptions"];
                    console.log("seasonalColorOptions=", seasonalColorOptions);
                    let generalForm = JSON.parse(JSON.stringify(this.$store.state[this.namespace]));
                    this.callGetSeasonalData([seasonalToAdd], this.$options.chart, generalForm, this.seasonalDataArray, seasonalColorOptions, this);
                }

                if (newAvailableSeasonals.length < oldAvailableSeasonals.length) {
                    //  oldSeasonals = this.seasonals.slice(0);
                }
            },
            addATRPanel: function (addATRPanel) {
                console.log("watch addATRPanel = ", addATRPanel);
                let generalForm = JSON.parse(JSON.stringify(this.$store.state[this.namespace]));
                addATRPanel ? addAtrPanel(this.$options.chart, generalForm) : removeAtrPanel(this.$options.chart);
            },
            addCCIPanel: function (addCCIPanel) {
                let generalForm = JSON.parse(JSON.stringify(this.$store.state[this.namespace]));
                addCCIPanel ? addCciPanel(this.$options.chart, generalForm) : removeCciPanel(this.$options.chart);
            },
            cciPeriod: function () {
                let generalForm = JSON.parse(JSON.stringify(this.$store.state[this.namespace]));
                addCciPanel(this.$options.chart, generalForm);
            },
            cciSource: function () {
                let generalForm = JSON.parse(JSON.stringify(this.$store.state[this.namespace]));
                addCciPanel(this.$options.chart, generalForm);
            },
            addMACDPanel: function (addMACDPanel) {
                // console.log("watch addMACDPanel = ", addMACDPanel);
                let generalForm = JSON.parse(JSON.stringify(this.$store.state[this.namespace]));
                addMACDPanel ? addMacdPanel(this.$options.chart, generalForm) : removeMacdPanel(this.$options.chart);
            },
            addBollinger: function (addBollinger) {
                console.log("watch addBollinger = ", addBollinger);
                let generalForm = JSON.parse(JSON.stringify(this.$store.state[this.namespace]));
                addBollinger ? addBollingerBands(this.$options.chart, generalForm) : removeBollinger(this.$options.chart, generalForm.bollingerPeriod);
            },
            bollingerPeriod: function () {
                let generalForm = JSON.parse(JSON.stringify(this.$store.state[this.namespace]));
                addBollingerBands(this.$options.chart, generalForm, generalForm.bollingerPeriod);
            },
            addSMA: function (addSMA) {
                console.log("watch addSMA: = ", addSMA);
                let generalForm = JSON.parse(JSON.stringify(this.$store.state[this.namespace]));
                addSMA ? addSma(this.$options.chart, generalForm) : removeSma(this.$options.chart);
            },
            smaLength: function (smaLength) {
                console.log("watch smaLength = ", smaLength);
                let generalForm = JSON.parse(JSON.stringify(this.$store.state[this.namespace]));
                smaLength ? addSma(this.$options.chart, generalForm) : removeSma(this.$options.chart);
            },
            addEMA: function (addEMA) {
                console.log("watch addEMA: = ", addEMA);
                let generalForm = JSON.parse(JSON.stringify(this.$store.state[this.namespace]));
                addEMA ? addEma(this.$options.chart, generalForm) : removeEma(this.$options.chart);
            },
            emaLength: function (emaLength) {
                console.log("watch emaLength = ", emaLength);
                let generalForm = JSON.parse(JSON.stringify(this.$store.state[this.namespace]));
                emaLength ? addEma(this.$options.chart, generalForm) : removeEma(this.$options.chart);
            },
            showTradingPeriod: function (showTradingPeriod) {
                console.log("watch showTradingPeriod = ", showTradingPeriod);
                let generalForm = JSON.parse(JSON.stringify(this.$store.state[this.namespace]));
                showTradingPeriod ? showTradingPeriodIndicator(this.$options.chart, generalForm) : hideTradingPeriod(this.$options.chart);
            },
            showCOTPanel: function (addCOTPanel) {
                let generalForm = JSON.parse(JSON.stringify(this.$store.state[this.namespace]));
                if (addCOTPanel) {
                    addCotPanel(this.$options.chart, this.parsedData, generalForm, this);
                } else {
                    $("#no_cot_message").remove();
                    removeCotPanel(this.$options.chart);
                }
            },
            open: function (newOpen, oldOpen) {
                console.log("watch: newOpen=", newOpen, " oldOpen=", oldOpen);
                let generalForm = JSON.parse(JSON.stringify(this.$store.state[this.namespace]));
                // console.log("this.$options.chart=", this.$options.chart);
                if (Object.keys(this.$options.chart).length > 0) {
                    showTradingPeriodIndicator(this.$options.chart, generalForm);
                }
            },
            close: function (newClose, oldClose) {
                console.log("watch: newClose=", newClose, " oldClose=", oldClose);
                let generalForm = JSON.parse(JSON.stringify(this.$store.state[this.namespace]));
                if (Object.keys(this.$options.chart).length > 0) {
                    showTradingPeriodIndicator(this.$options.chart, generalForm);
                }
            },
            locale: function (locale) {
                console.log("watch: locale=", locale);
                let generalForm = JSON.parse(JSON.stringify(this.$store.state[this.namespace]));
                this.createChart(generalForm, this.divName);
            },
            horizontalScrollbar: function (horizontalScrollbar) {
                console.log("watch: horizontalScrollbar=", horizontalScrollbar);
                if (typeof this.$options.chart.scrollbarX !== 'undefined') {
                    this.$options.chart.scrollbarX.disabled = horizontalScrollbar;
                }
            },
            buySell() {
                console.log("watching buySell");
                let generalForm = JSON.parse(JSON.stringify(this.$store.state[this.namespace]));
                showTradingPeriodIndicator(this.$options.chart, generalForm);
            }
        },
        methods: {
            clickHandler() {
                console.log("clickHandler() starting.");
                //  console.log("this.$options.chart.plugins=", this.$options.chart.plugins);
                //  console.log("this.$options.chart.plugins.values[0].data=", this.$options.chart.plugins.values[0].data);
                let annotation_data = this.$options.chart.plugins.values[0].data;
                //   console.log("annotation_data=", annotation_data);
                this.$store.dispatch(this.namespace + "/chartParameters/storeAnnotationData", annotation_data);

            },
            callGetSeasonalData: _.debounce(function (seasonalToAdd, chart, generalForm, seasonalDataArray, seasonalColorOptions, that) {
                console.log("callGetSeasonalData() starting.");
                getSeasonalData(seasonalToAdd, chart, generalForm, seasonalDataArray, seasonalColorOptions, that);
            }, 100),

            doChangePeriod(id) {
                // console.log("doChangePeriod() starting. id=", id);
                //  if (this.activeModuleName === this.namespace) {
                let generalForm = JSON.parse(JSON.stringify(this.$store.state[this.namespace]));
                //  console.log("this.$options.chart=", this.$options.chart);
                if (Object.keys(this.$options.chart).length > 0) {
                    changePeriod(id, this.$options.chart, generalForm);
                }
            },
            removeChart: function () {
                console.log("removeChart() starting.");
                //  console.log("divName=", this.divName);
                if (document.getElementById(this.divName) !== null) {
                    document.getElementById(this.divName).innerHTML = "";
                    // document.getElementById(this.divName).style.border = '0px solid gray';
                }
            },
            addData(bar) {
                console.log("addData() starting. bar=", JSON.parse(JSON.stringify(bar)));
                //  console.log("this.$options.chart=", this.$options.chart);
                // console.log("this.$options.chart.series=", this.$options.chart.series);

                let mainSeries = this.$options.chart.series.values.find(x => x.id === "main-series");
                console.log("mainSeries.data=", mainSeries.data);
                let lastBar = mainSeries.data[mainSeries.data.length - 1];
                console.log("bar.date=", moment(bar.date).format("YYYYMMDD"), " lastBar.date=", moment(lastBar.date).format("YYYYMMDD"));

                if (moment(bar.date).format("YYYYMMDD") === moment(lastBar.date).format("YYYYMMDD")) {
                    console.log("Replacing last bar.");

                        mainSeries.data[mainSeries.data.length - 1] = bar;

                        this.$options.chart.series.each(function (series) {
                        if (!series.id.includes("-y") && !series.id.includes("scrollbar") && !series.id.includes("profit-loss") && !series.id.includes("horizontal")) {
                            console.log("series.id=", series.id);
                            series.invalidateData();
                        }
                    });
                    // console.log("lastBar=", lastBar);
                    // console.log("mainSeries.data=", mainSeries.data);
                } else {
                    console.log("Adding new bar.");
                    mainSeries.addData(JSON.parse(JSON.stringify(bar)), 0);
                }

            },
            createChart: function (generalForm, divName = "chartDiv", chartId = "basic-chart") {
                // console.log("createChart() starting. divName=", divName);
                //  this.$options.chart.dispose && this.$options.chart.dispose(); //Not sure if this works.
                if (this.$options.chart.dispose) {
                    //  console.log("disposing chart.");
                    this.$options.chart.dispose();
                }

                let parsedData = this.parsedData;
                // console.log("parsedData=", JSON.parse(JSON.stringify(parsedData)));

                if (parsedData === null || ["none", "Not enough data."].includes(parsedData.values)) {
                    document.getElementById(this.divName).innerHTML =
                            "<p style='color: red; font-size: 12px; height: 100%; margin-top: 100px'>Unable to make a chart from the current selections.</p>";
                    return;
                }

                //  this.generalForm = generalForm;
                // this.$store.commit(this.namespace + "/browserSideOnly/setMinUnreturnedSeasonal", 40);

                // Set up number format
                let digits = spreadDigits(generalForm.selected[0], generalForm.instrument);
                // console.log("digits=", digits);
                let formatString = "#,###." + "0".repeat(digits);

                if (document.getElementById(divName) !== null) {
                    // document.getElementById(divName).style.border = '1px solid gray';
                }

                Object.values(parsedData.values).forEach((x) => {
                    if (generalForm.program === "TimeSeriesCharts") {
                        x.date = new Date(x.date);
                    } else {
                        x.date = moment(x.date, "YYYYMMDD").toDate();
                    }
                });

                if (generalForm.instrument === "future" && typeof parsedData.seasonal !== "undefined" && parsedData.seasonal !== "Not enough data.") {
                    let seasonalDataArray = Object.keys(parsedData.seasonal.values).map(
                            (date) => ({date: moment(date, "YYYYMMDD").format("YYYY-MM-DD"), ...parsedData.seasonal.values[date]})
                    );
                    // console.log("seasonalDataArray=", [...seasonalDataArray]);
                    parsedData.seasonal.values = seasonalDataArray;
                }
                // console.log("parsedData=", parsedData);

                am4core.addLicense("CH208485191");
                let chart = am4core.create(divName, am4charts.XYChart);
                chart.id = chartId;
                // console.log("chartId=", chartId);

                //  console.log("this.$store.state.settings.locale=", this.$store.state.settings.locale);
                let localeFile;
                switch (this.$store.state.settings.locale) {
                    case "de-DE":
                        localeFile = am4lang_de_DE;
                        break;
                    case "en-US":
                        localeFile = am4lang_en_US;
                        break;
                    case "es-ES":
                        localeFile = am4lang_es_ES;
                        break;
                    case "it-IT":
                        localeFile = am4lang_it_IT;
                        break;
                }
                chart.language.locale = localeFile;

                chart.padding(0, 15, 10, 15);
                chart.dateFormatter.dateFormat = "MMM dd, yyyy";
                chart.responsive.enabled = true;

                let title = chart.titles.create();
                title.text = generalForm.chartParameters.hiddenFeatures.title ? "" : generalForm.instrument === "future" ?
                        this.$store.getters[this.namespace + "/displayTicker"] : spreadTitle(generalForm);

                title.fontSize = 11;
                title.fontWeight = "bold";
                title.marginTop = 2;
                title.marginBottom = 16;

                let chartData;
                console.log("parsedData=", JSON.parse(JSON.stringify(parsedData)));

                if (generalForm.instrument === "future" && generalForm.program !== "TimeSeriesCharts") {
                    chartData = this.truncateData();
                } else {
                    chartData = parsedData.values;
                }
                this.chartData = chartData;
                // console.log("chartData=", chartData);
                // console.log("chartData.map(x => x.date)=", chartData.map(x => x.date));

                let end = chartData[chartData.length - 1].date;
                this.$store.commit(this.namespace + "/chartParameters/setMainSeriesEnd", end);

                // console.log("this.generalForm=", this.generalForm);

                chart.leftAxesContainer.layout = "vertical";

                let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
                dateAxis.id = "date-axis";
                dateAxis.renderer.grid.template.location = 0;
                dateAxis.renderer.ticks.template.length = 8;
                dateAxis.renderer.ticks.template.strokeOpacity = 0.1;
                dateAxis.renderer.grid.template.disabled = false;
                dateAxis.renderer.ticks.template.disabled = true;
                dateAxis.renderer.ticks.template.strokeOpacity = 0.2;
                dateAxis.renderer.minLabelPosition = 0.01;
                dateAxis.renderer.maxLabelPosition = 0.99;
                dateAxis.keepSelection = false;
                dateAxis.minHeight = 30;
                dateAxis.dateFormats.setKey("day", "MMM dd, yyyy");
                dateAxis.tooltipDateFormat = generalForm.program === "TimeSeriesCharts" ? ("day", "EEEE, MMM dd, yyyy HH:mm") : ("day", "EEEE, MMM dd, yyyy");
                dateAxis.groupData = false;
                dateAxis.skipEmptyPeriods = generalForm.chartParameters.skipEmptyPeriods;
                //  dateAxis.minZoomCount = 5;

                let mainAxis = chart.yAxes.push(new am4charts.ValueAxis());
                mainAxis.id = "main-axis";
                mainAxis.tooltip.disabled = false;
                mainAxis.renderer.baseGrid.disabled = false;
                mainAxis.renderer.baseGrid.strokeOpacity = 0.4;

                mainAxis.height = am4core.percent(100);
                mainAxis.renderer.inside = true;
                mainAxis.renderer.labels.template.verticalCenter = "bottom";
                mainAxis.renderer.labels.template.padding(2, 2, 2, 2);
                //mainAxis.renderer.maxLabelPosition = 0.95;
                mainAxis.renderer.fontSize = 10;
                if (generalForm.instrument === "future") {
                    mainAxis.title.text =
                            typeof decode !== "undefined"
                            ? decode(parsedData.units)
                            : parsedData.units;
                } else {
                    mainAxis.title.text = "USD";
                }
                mainAxis.title.fontWeight = "bold";
                mainAxis.title.fontSize = "11";
                // mainAxis.numberFormatter.numberFormat = formatString; // This causes bad numbering of the mainAxis labels.

                mainAxis.adjustLabelPrecision = true;
                mainAxis.includeRangesInMinMax = true;

                // Create series
                let series;
                if (generalForm.stockGraphType == "line" || generalForm.legs > 1) {
                    series = chart.series.push(new am4charts.LineSeries());
                    series.id = "main-series";
                    series.dataFields.dateX = "date";
                    series.clustered = false;
                    series.data = chartData;
                    series.dataFields.valueY = "close";
                    series.yAxis = mainAxis;
                    series.tooltipText = generalForm.chartParameters.balloons
                            ? "close: {valueY.value.formatNumber('" + formatString + "')}"
                            : "";
                    series.name = "close";
                    series.hiddenInLegend = true;
                    //  series.legendSettings.labelText = "[{color}]{name}:[/]";
                    //  series.legendSettings.itemValueText = "[{color}]{valueY}";
                    series.stroke = "teal";
                    series.tooltip.getFillFromObject = false;
                    series.tooltip.background.fill = "teal";
                    series.showOnInit = false;

                    if (generalForm.showBullets) {
                        addBullets(chart);
                    }
                } else if (generalForm.stockGraphType == "ohlc") {
                    series = chart.series.push(new am4charts.OHLCSeries());
                    series.id = "main-series";
                    series.data = chartData;
                    series.dataFields.dateX = "date";
                    series.dataFields.openValueY = "open";
                    series.dataFields.valueY = "close";
                    series.dataFields.lowValueY = "low";
                    series.dataFields.highValueY = "high";
                    series.clustered = false;
                    series.tooltipText = generalForm.chartParameters.balloons
                            ? "open: {openValueY.value.formatNumber('" +
                            formatString +
                            "')}\nhigh: {highValueY.value.formatNumber('" +
                            formatString +
                            "')}\nlow: {lowValueY.value.formatNumber('" +
                            formatString +
                            "')}\nclose: {valueY.value.formatNumber('" +
                            formatString +
                            "')}"
                            : "";
                    //  series.name = parsedData.title;
                    series.defaultState.transitionDuration = 0;
                    // series.legendSettings.labelText = "[{column.fill}]Open: {openValueY} \nLow: {lowValueY} \nHigh: {highValueY} \nClose: {valueY}[/]";
                    // series.legendSettings.labelText = "[{column.fill}]open: {openValueY}, high: {highValueY}, low: {lowValueY}, close: {valueY}[/]";
                    series.hiddenInLegend = true;
                    series.showOnInit = false;

                    // candlestick series colors are set in states.
                    series.riseFromOpenState.properties.fill = "teal";
                    series.riseFromOpenState.properties.stroke = "teal";
                    series.dropFromOpenState.properties.fill = "firebrick";
                    series.dropFromOpenState.properties.stroke = "firebrick";
                } else {
                    series = chart.series.push(new am4charts.CandlestickSeries());
                    series.id = "main-series";
                    series.data = chartData;
                    series.dataFields.dateX = "date";
                    series.dataFields.openValueY = "open";
                    series.dataFields.valueY = "close";
                    series.dataFields.lowValueY = "low";
                    series.dataFields.highValueY = "high";
                    series.clustered = false;
                    series.tooltipText = generalForm.chartParameters.balloons
                            ? "open: {openValueY.value.formatNumber('" +
                            formatString +
                            "')}\nhigh: {highValueY.value.formatNumber('" +
                            formatString +
                            "')}\nlow: {lowValueY.value.formatNumber('" +
                            formatString +
                            "')}\nclose: {valueY.value.formatNumber('" +
                            formatString +
                            "')}"
                            : "";
                    //  series.name = parsedData.title;
                    series.defaultState.transitionDuration = 0;
                    // series.legendSettings.labelText = "[{column.fill}]Open: {openValueY} \nLow: {lowValueY} \nHigh: {highValueY} \nClose: {valueY}[/]";
                    //  series.legendSettings.labelText = "[{column.fill}]open: {openValueY},  high: {highValueY},low: {lowValueY}, close: {valueY}[/]";
                    series.hiddenInLegend = true;
                    series.showOnInit = false;

                    // important!
                    // candlestick series colors are set in states.
                    series.riseFromOpenState.properties.fill = "teal";
                    series.riseFromOpenState.properties.stroke = "teal";
                    series.dropFromOpenState.properties.fill = "firebrick";
                    series.dropFromOpenState.properties.stroke = "firebrick";
                }

                chart.cursor = new am4charts.XYCursor();
                chart.zoomOutButton.align = "right";

                // console.log("generalForm.chartParameters.hiddenFeatures=", generalForm.chartParameters.hiddenFeatures);
                //  if (!generalForm.chartParameters.hiddenFeatures.horizontalScrollbar) {
                let scrollbarX = new am4charts.XYChartScrollbar();
                let sbSeries = chart.series.push(new am4charts.LineSeries());
                sbSeries.id = "scrollbar-series";
                sbSeries.data = chartData;
                sbSeries.dataFields.valueY = "close";
                sbSeries.dataFields.dateX = "date";
                sbSeries.hiddenInLegend = true;
                scrollbarX.series.push(sbSeries);
                sbSeries.disabled = true;
                scrollbarX.marginBottom = 0;
                chart.scrollbarX = scrollbarX;
                chart.scrollbarX.parent = chart.bottomAxesContainer;

                chart.scrollbarX.disabled = this.horizontalScrollbar;

                //  }

                if (!generalForm.chartParameters.hiddenFeatures.verticalScrollbar) {
                    chart.scrollbarY = new am4core.Scrollbar();
                    let scrollbarHeight = 0.86 * 0.8 * mainAxis.maxHeight;
                    chart.scrollbarY.height = scrollbarHeight;
                    chart.scrollbarY.parent = chart.rightAxesContainer;
                }

                if (!generalForm.chartParameters.hiddenFeatures.legends) {  //This displays the seasonal legend.
                    let width = $("#" + divName).width();
                    //  console.log("width=", width);
                    chart.legend = new am4charts.Legend();
                    //  chart.legend.position = "absolute";
                    chart.legend.contentAlign = "left";
                    chart.legend.dx = 0.4 * width;
                    chart.legend.dy = 10;
                    chart.legend.parent = chart.tooltipContainer;
                    // chart.legend.markers.template.disabled = false;
                    chart.legend.useDefaultMarker = true;
                    chart.legend.fontSize = "10";
                    chart.legend.valueLabels.template.textAlign = "left";
                    // Create container to hold our hover labels

                    // reduce the marker + container size so the text maintains alignment
                    let marker = chart.legend.markers.template;
                    marker.width = 8;
                    marker.height = 8;

                    // set the marker's corner radius to 1
                    // chart.legend.markers.template.children.getIndex(0).cornerRadius(1, 1, 1, 1);
                }

                chart.exporting.menu = new am4core.ExportMenu();
                chart.exporting.menu.align = "left";
                chart.exporting.menu.verticalAlign = "top";

                chart.exporting.menu.items = [
                    {
                        label: "...",
                        menu: [
                            {
                                label: "Image",
                                menu: [
                                    {type: "png", label: "PNG"},
                                    {type: "jpg", label: "JPG"},
                                    {type: "svg", label: "SVG"},
                                    {type: "pdf", label: "PDF"},
                                ],
                            },
                            {
                                label: "Print",
                                type: "print",
                            },
                        ],
                    },
                ];

                let annotation = chart.plugins.push(new am4plugins_annotation.Annotation());
                annotation.data = generalForm.chartParameters.annotationData;
                annotation.deactivate();

                if (this.namespace === "generalFormModulePlaybackPage") {
                    chart.zoomOutButton.disabled = true;
                }

                this.$options.chart = chart;

                let inputDiv = document.querySelector("#" + this.namespace + "InputDiv");
                // console.log("inputDiv=", inputDiv);
                let inputHeight = inputDiv !== null ? inputDiv.offsetHeight : 0;
                //  console.log("inputHeight=", inputHeight);
                this.$store.commit(this.namespace + "/browserSideOnly/setInputDivHeight", inputHeight);

                if (typeof this.$parent.$parent.$parent.$parent.setChartHeight !== "undefined") {
                    // console.log("this.$parent.$parent.$parent.$parent=", this.$parent.$parent.$parent.$parent);
                    this.$parent.$parent.$parent.$parent.setChartHeight();
                } else {
                    if (this.namespace === "generalFormModulePlaybackPage") {
                        const elem = document.getElementById(this.namespace + "-chartDiv");
                        elem.style.height = "700px";
                    }
                }

                let that = this;
                chart.events.on("ready", function () {
                    let generalForm = JSON.parse(JSON.stringify(that.$store.state[that.namespace]));
                    //let series = chart.series.values.find((x) => x.id === "main-series"); //  let series = chart.map.getKey("main-series"); This didn't work here!
                    // updateValues(series.dataItems.last);

                    that.profitLoss();

                    if ((generalForm.legs == 1 || (["BasicCharts", "Playback"].includes(generalForm.program) &&
                            generalForm.dataSource !== "eod")) && generalForm.addVolumeProfile) {
                        addVolumeProfileIndicator(chart, generalForm);
                    }

                    if ((generalForm.legs == 1 || (["BasicCharts", "Playback"].includes(generalForm.program) &&
                            generalForm.dataSource !== "eod")) && generalForm.addVolumePanel) {
                        addVolume(chart, generalForm);
                    }

                    dateAxis.events.on("rangechangeended", function () {
                        if (generalForm.addVolumeProfile) {
                            // console.log("that=", that);
                            setTimeout(function () {
                                updateVolumeProfile(chart, generalForm);
                            }, 70);
                        }
                    });


                    let mainAxis = chart.map.getKey("main-axis");
                    series.events.on("startendchanged", () => {
                        console.log("startendchanged");
                        //chart.zoomAxes(chart.yAxes, {start: 0, end: 1});
                        // console.log("mainAxis._minReal=", mainAxis._minReal);
                        // console.log("mainAxis._maxReal=", mainAxis._maxReal);
                        mainAxis.zoomToValues(mainAxis._minReal, mainAxis._maxReal);
                        // let dateAxis = chart.map.getKey("date-axis");
                        // dateAxis.zoomToDates(0, 1);
                        // chart.initialZoom();

                    });
                });

                if (generalForm.instrument === "future" && generalForm.program !== "TimeSeriesCharts") {
                    //  console.log("isSpreadOpen(generalForm.selected[0])=", isSpreadOpen(generalForm.selected[0]));
                    //  console.log("generalForm.showSeasonals=", generalForm.showSeasonals);
                    console.log("generalForm.seasonals=", generalForm.seasonals);
                    removeSeasonal(generalForm.seasonals, this.$options.chart);

                    if (generalForm.showSeasonals) {
                        this.seasonalDataArray = [];
                        let seasonalColorOptions = this.$store.getters[this.namespace + "/seasonalOptions"];
                        //  console.log("seasonalColorOptions=", seasonalColorOptions);
                        getSeasonalData(this.availableSeasonals.slice(0), this.$options.chart, generalForm, this.seasonalDataArray, seasonalColorOptions, this);
                    }
                    that.initialZoom();
                }

                // let indicatorNames = ["addATRPanel"];
                //  console.log("indicatorNames=", indicatorNames);

                if (generalForm.addCOTPanel) {
                    addCotPanel(chart, parsedData, generalForm, this);
                }
                if (generalForm.addMACDPanel) {
                    addMacdPanel(chart, generalForm);
                }
                if (generalForm.addRSIPanel) {
                    addRsiPanel(chart, generalForm.rsiPeriod, generalForm);
                }
                if (generalForm.addCCIPanel) {
                    addCciPanel(chart, generalForm);
                }
                if (generalForm.addATRPanel) {
                    addAtrPanel(chart, generalForm);
                }

                if (generalForm.showTradingPeriod) {
                    showTradingPeriodIndicator(chart, generalForm);
                }
                if (generalForm.addBollinger) {
                    addBollingerBands(chart, generalForm);
                }
                if (generalForm.addSMA) {
                    addSma(chart, generalForm);
                }
                if (generalForm.addEMA) {
                    addEma(chart, generalForm);
                }
                generalForm.addHorizontalLine &&
                        (generalForm.showPlaybackControl || this.namespace === "generalFormModulePlaybackPage") ? addLevels(chart, generalForm) : null;

                let info = chart.plotContainer.createChild(am4core.Container);
                info.width = 600;
                info.height = 15;
                info.x = 30;
                info.y = -9;
                info.padding(-4, 0, 0, 36);
                // info.background.fill = am4core.color("#000");
                // info.background.fillOpacity = 0.1;
                info.layout = "grid";

                function createLabel(field, title) {
                    let titleLabel = info.createChild(am4core.Label);
                    titleLabel.text = title === "date" ? "" : title.slice(0, 1) + ":";
                    titleLabel.marginRight = 2;
                    // titleLabel.minWidth = 60;
                    titleLabel.fontWeight = "bold";
                    titleLabel.fontSize = 10;
                    titleLabel.fill = "black";
                    titleLabel.events.on("hit", function () {
                        toggleMainSeries();
                    });

                    let valueLabel = info.createChild(am4core.Label);
                    valueLabel.id = title;
                    valueLabel.marginRight = 3;
                    if (title === "date")
                        valueLabel.minWidth = 70;
                    valueLabel.fontSize = 10;
                    valueLabel.fill = "black";
                    valueLabel.events.on("hit", function () {
                        toggleMainSeries();
                    });

                    function toggleMainSeries() {
                        console.log("series=", series);
                        console.log("series.hidden=", series.hidden);
                        if (series.hidden) {
                            series.show();
                            series.hidden = false;
                        } else {
                            series.hide();
                            series.hidden = true;
                        }
                    }
                }

                if (!generalForm.chartParameters.hiddenFeatures.legends) {
                    let mainSeries = chart.map.getKey("main-series");
                    createLabel("", "date", mainSeries.stroke);
                    createLabel("", "open", mainSeries.stroke);
                    createLabel("", "high", mainSeries.stroke);
                    createLabel("", "low", mainSeries.stroke);
                    createLabel("", "close", mainSeries.stroke);
                }

                // chart.numberFormatter.numberFormat = formatString;

                // Show overall close values when cursor is not shown
                chart.cursor.events.on("hidden", function () {
                    let series = chart.map.getKey("main-series");
                    if (!generalForm.chartParameters.hiddenFeatures.legends) {
                        updateValues(series.dataItems.last, series.name);
                    }
                });

                // Set up cursor's events to update the label
                chart.cursor.events.on("cursorpositionchanged", function () {
                    let series = chart.map.getKey("main-series");
                    let dataItem = dateAxis.getSeriesDataItem(series, dateAxis.toAxisPosition(chart.cursor.xPosition), true);
                    if (!generalForm.chartParameters.hiddenFeatures.legends) {
                        updateValues(dataItem, series.name);
                    }
                });

                function updateValues(dataItem) {
                    // console.log("dataItem=", dataItem);
                    let digits = generalForm.instrument === "future" ? spreadDigits(generalForm.selected[0]) : 2;
                    // console.log("digits=", digits);

                    if (typeof dataItem !== "undefined" && typeof dataItem["dateX"] !== "undefined" && dataItem._disposers.length === 2) { // Don't know about _disposers but check the dataItem object.
                        chart.map.getKey("date").text = moment(dataItem["dateX"]).format("MMM DD, YYYY") + "  ";
                        let close = chart.map.getKey("close");
                        if (generalForm.stockGraphType === "line" || generalForm.legs > 1) {
                            close.text = dataItem["valueY"].toFixed(digits);
                            close.fill = "teal";
                        } else {
                            let open = chart.map.getKey("open");
                            let high = chart.map.getKey("high");
                            let low = chart.map.getKey("low");

                            open.text = dataItem["openValueY"].toFixed(digits);
                            high.text = dataItem["highValueY"].toFixed(digits);
                            low.text = dataItem["lowValueY"].toFixed(digits);
                            close.text = dataItem["closeValueY"].toFixed(digits);

                            let color = dataItem["closeValueY"] - dataItem["openValueY"] < 0 ? "firebrick" : "teal";
                            open.fill = color;
                            high.fill = color;
                            low.fill = color;
                            close.fill = color;
                        }
                    }
            }
            },
            truncateData: function () {
                // console.log("truncateData() starting.");
                let generalForm = JSON.parse(JSON.stringify(this.$store.state[this.namespace]));

                const {truncate, selected} = generalForm;
                // console.log("truncate=", truncate, "  selected=", selected);

                this.expiration = typeof this.parsedData.expiration !== "undefined" ? this.parsedData.expiration : getSpreadExpiration(selected[0]);
                // console.log("this.expiration=", this.expiration);

                let seasonalDataObject = this.parsedData.seasonal;
                //  console.log("seasonalDataObject=", {...seasonalDataObject});

                let lastDateOfSeasonal;
                if (typeof seasonalDataObject !== "undefined" && seasonalDataObject !== "Not enough data.") {
                    lastDateOfSeasonal = seasonalDataObject.values[seasonalDataObject.values.length - 1].date;
                } else {
                    lastDateOfSeasonal = this.expiration;
                }

                let end = moment(Math.min(this.expiration, lastDateOfSeasonal.toString().replace(/-/g, "")), "YYYYMMDD").add(1, "days").format("YYYY-MM-DD");
                // console.log("end=", end);
                // this.generalForm.chartParameters.end = end;
                this.$store.commit(this.namespace + "/chartParameters/setEnd", end);

                this.checkingDate = moment(this.expiration, "YYYYMMDD").subtract(6, "months");
                //console.log("this.checkingDate=", this.checkingDate.format("MMM D, YYYY"));

                let currentDate = moment();
                // console.log("currentDate=", currentDate);

                let start;
                if (!currentDate.isBefore(this.checkingDate)) {
                    // console.log("currentDate is after this.checkingDate");
                    start = moment(end).subtract(truncate, "year").format("YYYY-MM-DD");
                } else {
                    //  console.log("currentDate is NOT after this.checkingDate");
                    start = currentDate.clone().subtract(6 + (truncate - 1) * 12, "months").format("YYYY-MM-DD");
                }
                // console.log("start=", start, " end=", end);
                this.$store.commit(this.namespace + "/chartParameters/setStart", start);
                // this.generalForm.chartParameters.start = start;
                //  console.log("this.parsedData.values=", this.parsedData.values);
                let values = Object.values(this.parsedData.values).filter(
                        x => !moment(x.date).isBefore(moment(start))
                // (x) => x.date >= start
                );
                //  console.log("values=", values);
                return values;
            },
            initialZoom: function () {
                //  console.log("initialZoom() starting.");
                let generalForm = JSON.parse(JSON.stringify(this.$store.state[this.namespace]));
                if (this.$store.state[this.namespace].chartParameters.initialZoom) {
                    let {truncate} = generalForm;
                    //  console.log("this.checkingDate=", this.checkingDate.format("MMM D, YYYY"));
                    let currentDate = moment();
                    //  console.log("currentDate=", currentDate);

                    //  console.log("this.chartData=", this.chartData);
                    let firstChartDate = moment(this.chartData[0].date);
                    //  this.$store.commit('generalForm/chartParameters/setFirstChartDate', firstChartDate.format('YYYY-MM-DD'));
                    //  this.$store.commit('generalForm/chartParameters/setLastChartDate', this.chartData[this.chartData.length - 1].date);

                    // console.log("firstChartDate =", firstChartDate);
                    // console.log("truncate=", truncate);

                    let start, end;
                    if (!currentDate.isBefore(this.checkingDate)) {
                        //  console.log("currentDate is after checkingDate");
                        end = moment(this.expiration, "YYYYMMDD");  //.add(1, "days");
                        start = moment(end).subtract(truncate, "year");
                    } else {
                        // console.log("currentDate is NOT after checkingDate");
                        end = currentDate.clone().add(6, "months");  //.add(1, "days");
                        start = currentDate.clone().subtract(6 + (truncate - 1) * 12, "months");
                    }

                    if (start.isBefore(firstChartDate)) {
                        start = firstChartDate.clone();
                    }

                    // console.log("start=", start.format('YYYY-MM-DD'));
                    // console.log("end=", end.format('YYYY-MM-DD'));
                    //   console.log("Zooming....................");
                    //  console.log("this.chartData=", this.chartData);

                    this.$store.commit(this.namespace + "/chartParameters/setStart", start.clone().add(6, "months").format("YYYY-MM-DD"));
                    this.$store.commit(this.namespace + "/chartParameters/setEnd", end.format("YYYY-MM-DD"));

                    let dateAxis = this.$options.chart.map.getKey("date-axis");
                    dateAxis.zoomToDates(start.toDate(), end.clone().add(4, "days").toDate());
                    //  console.log("initialZoom() done.");
                }

                if (!generalForm.chartParameters.hiddenFeatures.expiration) {
                    addExpirationGuide(this.$options.chart, generalForm);
                }
            }
        }
    };
</script>
