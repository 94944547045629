let historyInitialState = {
    "instrument": "future",
    "legs": 1,
    "sampleContract": ["S2021H", "S2021K", "S2021N", "S2021U"],
    "selected": ["S2022H"],
          "intracommodity": false,
    "p": [
        1,
        -1,
        1,
        1
    ],
    "unitMove": [
        50,
        50,
        50,
        50
    ],
    "unit": "",
    "y1": 15,
    "generator": "SeasonalsGenerator",
    program: "History",
    "mult": [
        1,
        1,
        1,
        1
    ],
    "spreadP": 1,
    "overwriteShow": false,
    "addProfitLoss": false,
    "showTradingPeriod": true,
    "aligned": true,
    "user": "A4A-user0",
    "password": "M1-K8xQgmR",
    "constrainContractAligner": false,
    "study": "history",
    "watchList": [],
    "longtermChartColors": "yearly",
    "numberOfContractsApart": "0",
    "language": "en",
    show3D: false,
    "chartParameters": {
        chartsPerRow: 2,
        rowsPerPage: 2,
        "balloons": true,
        "height": 600,
        "page": 1,
        "showMapHover": false,
        "showMapHoverLocationOnSeasonal": false,
        "parameterToMap": "percentUp",
        "start": "",
        "end": "",
        "levels": [],
        "numberOfCharts": "",
        "initialZoom": true,
        "skipEmptyPeriods": false
    },
    "playback": {
        "i": null
    },
    "legendEnabled": false
};

export {historyInitialState};