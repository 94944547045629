import $ from "jquery";
import moment from 'moment';
import * as fb from "../firebase";
import _ from "lodash";

const basicChartInput = () =>
  import("@/components/programs/BasicChart/basic-chart-input");
const stockChartInput = () =>
  import("@/components/programs/StockChart/stock-chart-input");
const calculatorInput = () =>
  import("@/components/programs/Calculator/calculator-input");
const openSpreadsInput = () =>
  import("@/components/programs/OpenSpreads/open-spreads-input");
const forwardCurvesInput = () =>
  import("@/components/programs/ForwardCurves/forward-curves-input");
const searchInput = () => import("@/components/programs/Search/search-input");
const tradeMapsInput = () =>
  import("@/components/programs/TradeMaps/trade-maps-input");
const spreadCoverageInput = () =>
  import("@/components/programs/SpreadCoverage/spread-coverage-input");
const longTermChartsInput = () =>
  import("@/components/programs/LongTermCharts/long-term-charts-input");
const historyInput = () =>
  import("@/components/programs/History/history-input");
const timeSeriesInput = () => 
  import("@/components/programs/TimeSeriesChart/time-series-chart-input");
const firestoreSearchInput = () => 
  import("@/components/programs/FirestoreSearch/firestore-search-input");
const watchListInput = () => 
  import("@/components/programs/WatchList/watch-list-input");
const tradeExplorerInput = () => 
  import("@/components/programs/TradeExplorer/trade-explorer-input");
  
  import { basicChartInitialState } from "@/components/programs/BasicChart/basic-chart-initial-state";
  import { stockChartInitialState } from "@/components/programs/StockChart/stock-chart-initial-state";
  import { calculatorInitialState } from "@/components/programs/Calculator/calculator-initial-state";
  import { openSpreadsInitialState } from "@/components/programs/OpenSpreads/open-spreads-initial-state";
  import { forwardCurvesInitialState } from "@/components/programs/ForwardCurves/forward-curves-initial-state";
  import { searchInitialState } from "@/components/programs/Search/search-initial-state";
  import { tradeMapsInitialState } from "@/components/programs/TradeMaps/trade-maps-initial-state";
  import { spreadCoverageInitialState } from "@/components/programs/SpreadCoverage/spread-coverage-initial-state";
  import { longTermChartsInitialState } from "@/components/programs/LongTermCharts/long-term-charts-initial-state";
  import { historyInitialState } from "@/components/programs/History/history-initial-state";
  import { timeSeriesChartInitialState } from "@/components/programs/TimeSeriesChart/time-series-chart-initial-state";
  import { firestoreSearchInitialState } from "@/components/programs/FirestoreSearch/firestore-search-initial-state";
  import { watchListInitialState } from "@/components/programs/WatchList/watch-list-initial-state";
  import { tradeExplorerInitialState } from "@/components/programs/TradeExplorer/trade-explorer-initial-state";
  
  import { getSymbolConversions } from "@/assets/js/TableConversionCSI_YAHOO.js";

let commoditiesArray = {};

function getCommoditiesArray() {
    return commoditiesArray;
}

function setCommoditiesArray(array) {
    commoditiesArray = array;
}

function areSameUnits(spread) {
    let contracts = spread.replace(/\s/g, '').split('/');
    let units = contracts.map(x => commodityUnits(contractNameDecomposer(x).commoditySymbol));
    let sameUnits = units.every((val, i, arr) => val === arr[0]);
    return sameUnits;
}

function areSameUnitMoves(spread) {
    let contracts = spread.replace(/\s/g, '').split('/');
    let unitMove = contracts.map(x => getUnitMove(contractNameDecomposer(x).commoditySymbol));
    // console.log("unitMove=" + unitMove);
    let sameUnitMoves = unitMove.every((val, i, arr) => val === arr[0]);
    return sameUnitMoves;
}

function areSameUnitsAndUnitMoves(spread) {
    return areSameUnitMoves(spread) && areSameUnits(spread);
}

function commodityUnits(commodity) {
    return getCommoditiesArray().find(x => x.symbol === commodity).units;
}

function getUnitMove(commodity) {
    return getCommoditiesArray().find(x => x.symbol === commodity).unitMove;
}

function contractNameDecomposer(name) {
    // console.log("contractNameDecomposer() starting.");
    // console.log("contract: " + name);
    let temp = {};
    if (name != undefined) {
        temp.monthSymbol = name.substring(name.length - 1, name.length);
        temp.commoditySymbol = name.substring(0, name.length - 5);
        temp.year = name.substring(name.length - 5, name.length - 1);
        //  console.log("monthSymbol=" + temp.monthSymbol + "  commoditySymbol=" + temp.commoditySymbol + "\n");
    }
    return temp;
}

function displayCommodity(commodity, symbols){
    let com = (symbols === "old" || typeof symbols === "undefined") ? commodity : eod_to_barchart_conv(commodity);
    return com;
}

function eodContractToBarchartContract(contract) {
    let commodity = contractNameDecomposer(contract).commoditySymbol;
    let month = contractNameDecomposer(contract).monthSymbol;
    let year = contractNameDecomposer(contract).year;

    let oneDigitYear = eod_to_barchart_conv(commodity) + month + year.substring(3, 4);
    let twoDigitYear = eod_to_barchart_conv(commodity) + month + year.substring(2, 4);
    let fourDigitYear = eod_to_barchart_conv(commodity) + month + year;
    let obj = { oneDigitYear: oneDigitYear, twoDigitYear: twoDigitYear, fourDigitYear: fourDigitYear }
    // console.log("obj=", obj);
    return obj;
}

function eodContractToYahooContract(contract) {
    console.log("eodContractToYahooContract() starting. contract=", contract);
    let commodity = contractNameDecomposer(contract).commoditySymbol;
    let month = contractNameDecomposer(contract).monthSymbol;
    let year = contractNameDecomposer(contract).year;

    let commodityConversionObject = getSymbolConversions().find(x => x.CSI === commodity);
    console.log("commodityConversionObject=", commodityConversionObject);

   if(commodityConversionObject.SYMBOL === "-"){
    return "no_data";
   }else{
    let oneDigitYear = commodityConversionObject.SYMBOL + month + year.substring(3, 4) + commodityConversionObject.POSTFIX;
    let twoDigitYear = commodityConversionObject.SYMBOL + month + year.substring(2, 4) + commodityConversionObject.POSTFIX;
    let fourDigitYear = commodityConversionObject.SYMBOL + month + year + commodityConversionObject.POSTFIX;
    let obj = { oneDigitYear: oneDigitYear, twoDigitYear: twoDigitYear, fourDigitYear: fourDigitYear }
    // console.log("obj=", obj);
    return obj;
   }
}
// console.log("eodContractToYahooContract('C2022H')=", eodContractToYahooContract('C2022H'));

function eodTickerToYahooTickerLegs(spread){
    let originalLegs = spread.split(/(?=[.+-/])/).map(x => x.replace(/\s/g, ''));
   // console.log("originalLegs=", originalLegs);

    let newLegs = originalLegs.map(leg => {
       // console.log("leg=", leg);
        let contract = leg.substr(leg.indexOf('*')+1).replace("+", "").replace("-", "");
       // console.log("contract=", contract);
        let newContract = eodContractToYahooContract(contract).twoDigitYear;
        let newLeg = leg.replace(contract, newContract);
        return newLeg;
    });
   // console.log("newLegs=", newLegs);
    return newLegs;
}
// console.log("eodTickerToYahooTickerLegs('C2021Z-2*HO1993F + S1993H')=", eodTickerToYahooTickerLegs('C2021Z+2*HO1993F - S1993H'));

function compareContracts(contract1, contract2) {
    //  console.log("contract1=", contract1, " contract2=", contract2);
    let cND = contractNameDecomposer(contract1);
    let month1 = cND.monthSymbol;
    let month2 = contractNameDecomposer(contract2).monthSymbol;
    let year1 = cND.year;
    let year2 = contractNameDecomposer(contract2).year;

    let compare;
    if (year1 < year2) {
        compare = -1;
    } else if (year1 > year2) {
        compare = 1;
    } else if (month1 < month2) {
        compare = -1;
    } else {
        compare = 1;
    }
    return compare;
}

function generalFormToBarchartTicker(generalForm) {
    // console.log("generalFormToBarchartTicker() starting.");
    //  console.log("generalForm=", generalForm);
    generalForm.barchartTicker = "";

    switch (generalForm.legs) {
        case 1:
            // console.log("1 leg");
            generalForm.spreadP = generalForm.p[0] > 0 ? 1 : -1;
            generalForm.spreadMult = generalForm.mult[0];
            generalForm.barchartTicker = eodContractToBarchartContract(generalForm.selected[0]).oneDigitYear;
            break;
        case 2: //  https://stackoverflow.com/questions/50752987/eslint-no-case-declaration-unexpected-lexical-declaration-in-case-block/50753272
            {
                //  console.log("2 legs");
                let mult = generalForm.mult;
                //  console.log("mult=", mult);
                if (mult[0] === mult[1]) {
                    let eodContracts = generalForm.selected[0].split('/');
                    let orderedEodContracts = [...eodContracts].sort(compareContracts);
                    //  console.log("eodContracts=", eodContracts);
                    //  console.log("orderedEodContracts=", orderedEodContracts);

                    let orderedP = orderedEodContracts.map(contract => {
                        let index = eodContracts.indexOf(contract);
                        return generalForm.p[index];
                    });
                    //  console.log("orderedP=", orderedP);

                    generalForm.barchartTicker = "_S_SP_" + orderedEodContracts.map(x => eodContractToBarchartContract(x).oneDigitYear).join('_');
                    generalForm.spreadP = orderedP[0] > 0 ? 1 : -1;
                    generalForm.spreadMult = mult[0];
                    return generalForm;
                } else {
                    generalForm.barchartTicker = "";
                }
                break;
            }
        case 3:
            {
                //   console.log("3 legs");
                let eodContracts = generalForm.selected[0].split('/');
                let orderedEodContracts = [...eodContracts].sort(compareContracts);
                //   console.log("generalForm.p=", generalForm.p);
                //   console.log("eodContracts=", eodContracts);
                //   console.log("orderedEodContracts=", orderedEodContracts);

                let orderedMult = orderedEodContracts.map(contract => {
                    let index = eodContracts.indexOf(contract);
                    return generalForm.mult[index];
                });
                let orderedP = orderedEodContracts.map(contract => {
                    let index = eodContracts.indexOf(contract);
                    return generalForm.p[index];
                });
                //  console.log("orderedMult=", orderedMult);
                //  console.log("orderedP=", orderedP);


                if (orderedMult[1] === 2 * orderedMult[0] && orderedMult[0] === orderedMult[2] && orderedP[0] === -orderedP[1] && orderedP[0] === orderedP[2]) {
                    generalForm.barchartTicker = "_S_BF_" + orderedEodContracts.map(x => eodContractToBarchartContract(x).oneDigitYear).join('_');
                    generalForm.spreadP = orderedP[0] > 0 ? 1 : -1;
                    generalForm.spreadMult = orderedMult[0];
                    return generalForm;
                } else {
                    generalForm.barchartTicker = "";
                }
                break;
            }
        case 4:
            {
                console.log("4 legs");
                let eodContracts = generalForm.selected[0].split('/');
                let orderedEodContracts = [...eodContracts].sort(compareContracts);
                console.log("generalForm.p=", generalForm.p);
                console.log("eodContracts=", eodContracts);
                console.log("orderedEodContracts=", orderedEodContracts);

                let orderedMult = orderedEodContracts.map(contract => {
                    let index = eodContracts.indexOf(contract);
                    return generalForm.mult[index];
                });
                let orderedP = orderedEodContracts.map(contract => {
                    let index = eodContracts.indexOf(contract);
                    return generalForm.p[index];
                });
                console.log("orderedMult=", orderedMult);
                console.log("orderedP=", orderedP);

                if (orderedMult[0] === orderedMult[1] && orderedMult[1] === orderedMult[2] && orderedMult[2] === orderedMult[3] &&
                    orderedP[1] === -orderedP[0] && orderedP[2] === orderedP[1] && orderedP[3] === -orderedP[2]) {
                    generalForm.barchartTicker = "_S_CF_" + orderedEodContracts.map(x => eodContractToBarchartContract(x).oneDigitYear).join('_');
                    generalForm.spreadP = orderedP[0] > 0 ? 1 : -1;
                    generalForm.spreadMult = orderedMult[0];
                    return generalForm;
                } else {
                    generalForm.barchartTicker = "";
                }
                break;
            }
    }
    return generalForm;
}

function eod_to_barchart_conv(symbol) {
    switch (symbol) {
        case "TY":
            return "ZN";

        case "TU":
            return "ZT";

        case "US":
            return "ZB";

        case "FV":
            return "ZF";

        case "AD":
            return "A6";

        case "B":
            return "CB";

        case "BP":
            return "B6";

        case "CB":
            return "BD";

        case "CD":
            return "D6";

        case "DC":
            return "DL";

        case "LH":
            return "HE";

        case "C":
            return "ZC";

        case "CSC":
            return "BJ";

        case "EC":
            return "E6";

        case "ED":
            return "GE";

        case "FC":
            return "GF";

        case "GO":
            return "LF";

        case "JY":
            return "J6";

        case "KW":
            return "KE";

        case "LB":
            return "LS";

        case "LC":
            return "LE";

        case "O":
            return "ZO";

        case "RR":
            return "ZR";

        case "SP":
            return "ES";

        case "SM":
            return "ZM";

        case "BO":
            return "ZL";

        case "S":
            return "ZS";

        /*  case "RC":
         return "D";
         */
        case "W":
            return "ZW";

        case "VX":
            return "VI";

        case "SF":
            return "S6";

        default:
            return symbol;

    }
}

function barchart_to_eod_conv(symbol) {
    switch (symbol) {
        case "ZN":
            return "TY";

        case "ZT":
            return "TU";

        case "ZB":
            return "US";

        case "ZF":
            return "FV";

        case "A6":
            return "AD";

        case "CB":
            return "B";

        case "B6":
            return "BP";

        case "BD":
            return "CB";

        case "BJ":
            return "CSC";

        case "D6":
            return "CD";

        case "DL":
            return "DC";

        case "HE":
            return "LH";

        case "ZC":
            return "C";

        case "E6":
            return "EC";

        case "GE":
            return "ED";

        case "GF":
            return "FC";

        case "LF":
            return "GO";

        case "J6":
            return "JY";

        case "KE":
            return "KW";

        case "LS":
            return "LB";

        case "LE":
            return "LC";

        case "ZO":
            return "O";

        case "ZR":
            return "RR";

        case "ES":
            return "SP";

        case "ZM":
            return "SM";

        case "ZL":
            return "BO";

        case "ZS":
            return "S";

        /*  case "D":
         return "RC";
         */
        case "ZW":
            return "W";

        case "VI":
            return "VX";

        case "S6":
            return "SF";

        default:
            return symbol;

    }
}

function barchartSpreadToEodSpread(spread) {
    let originalContracts = spread.split("_").filter(x => x.length > 2)
        .map(x => x.slice(0, -1) + "2" + x.slice(-1))
        .map(y => barchartContractToEodContract(y));
    // console.log("originalContracts=", originalContracts);

    let eodSpread = originalContracts.join("/");
    return eodSpread;
}

function barchartContractToEodContract(contract) {
    // console.log("contract=", contract);
    let year = "20" + contract.slice(-2);
    let month = contract.slice(-3, -2);
    let commodity = contract.slice(0, -3);
    let eodContract = barchart_to_eod_conv(commodity) + year + month;
    // console.log("year=", year, " month=", month, " commodity=", commodity + " eodContract=", eodContract);
    return eodContract;
}

function incrementContract(contract, years) {
    let commodity = contractNameDecomposer(contract).commoditySymbol;
    let month = contractNameDecomposer(contract).monthSymbol;
    let year = contractNameDecomposer(contract).year;
    return commodity + (parseInt(year) + years) + month;
}

function decrementSampleContract(contracts) {
    let newSampleContract = contracts.map(contract => incrementContract(contract, -1));
    return newSampleContract;
}

function createContractLists(sampleContract, legs) {
    let contractsArray = [];
    let openContractsArray = [];
    for (let i = 0; i < legs; i++) {
        let commodity = contractNameDecomposer(sampleContract[i]).commoditySymbol;
        let month = contractNameDecomposer(sampleContract[i]).monthSymbol;
        let contracts = Contracts(commodity, month);
        // console.log("contracts=", contracts);

        let openContracts = OpenContracts(commodity, month);
        contractsArray.push(contracts);
        openContractsArray.push(openContracts);
    }
    // console.log("contractsArray=", contractsArray);
    // console.log("openContractsArray=", openContractsArray);
    return { contractsArray: contractsArray, openContractsArray: openContractsArray };
}

function ContractWeightedN_tuplets(sampleContract, list) {
    // console.log("ContractWeightedN_tuplets starting.");
    // console.log("truncated sampleContract=", sampleContract);
    // console.log("list=", list);
    // console.log("openList=", openList);
    let numberOfLegs = sampleContract.length;
    // console.log("numberOfLegs=" + numberOfLegs);
    //let inDatabase;
    /* for (let i = 0; i < list.length; i++) {
     let inDatabase = list[i].includes(sampleContract[i]);
     console.log("inDatabase=", inDatabase);
     if (!inDatabase) {
     console.log("sampleContract[" + i + "]=", sampleContract[i]);
     sampleContract = decrementSampleContract(sampleContract);
     console.log("sampleContract=", sampleContract);
     programVue.$store.commit('generalForm/setSampleContract', sampleContract);
     break;
     }
     }*/

    let offset = [];
    for (let i = 0; i < list.length; i++) {
        let tempOffset = 0;
        for (let j = 0; j < list[i].length; j++) {
            //   console.log("list[i].length=" + list[i].length);
            //   console.log(list[i][j] + "   " + sampleContract[i]);
            if (list[i][j] == sampleContract[i])
                break;
            tempOffset++;
        }
        if (tempOffset == list[i].length) {
            tempOffset = 0;
        }
        offset[i] = tempOffset;
        //  console.log("offset[" + i + "]=" + offset[i]);
    }

    let offsetMax = 0;
    let offsetMin = 9999;
    for (let i = 0; i < numberOfLegs; i++) {
        offsetMax = Math.max(offsetMax, offset[i]);
        offsetMin = Math.min(offsetMin, offset[i]);
    }
    // console.log("offsetMax=" + offsetMax + " offsetMin=" + offsetMin);

    // The next loop re-normalizes the offset array.
    let newSampleContract = [];
    for (let i = 0; i < numberOfLegs; i++) {
        newSampleContract.push(list[i][offset[i] - offsetMin]);
        offset[i] = offset[i] - offsetMax;
        //console.log("After renormalization: offsetMax=" + offsetMax + " offset[" + i + "]=" + offset[i]);
    }
    //console.log("newSampleContract=", newSampleContract);

    let maxNumberOfSpreads = 0;
    for (let i = 0; i < numberOfLegs; i++) {
        let numberOfContracts = list[i].length;
        // console.log("numberOfContracts=" + numberOfContracts);
        maxNumberOfSpreads = Math.max(maxNumberOfSpreads, numberOfContracts);
    }
    // console.log("maxNumberOfSpreads=" + maxNumberOfSpreads);

    let returnArray = [];
    for (let j = 0; j < maxNumberOfSpreads; j++) {
        let rowArray = [];
        let save = true;
        for (let i = 0; i < numberOfLegs; i++) {
            let index = j + offset[i];
            // console.log("i=" + i + " j=" + j + " index=" + index + " list=" + list[i][index]);
            if (list[i][index] == undefined && index >= 0) {
                save = false;
            }
            if (index < 0) {
                rowArray[i] = "---------";
            } else {
                rowArray[i] = list[i][index];
            }
        }
        // console.log("rowArray=", rowArray);

        if (save) {
            returnArray.push(rowArray);
        }
    }

    let returnOpenArray = returnArray.filter(arr => {
        //  console.log("arr=", arr);
        let spread = arr.join('/');
        // console.log("spread=", spread);
        if (!spread.includes("-")) {
            let isOpen = isSpreadOpen(spread);
            // console.log("isOpen=", isOpen);

            /*  for (let i = 0; i < numberOfLegs; i++) {
             if (!contracts[i].includes("-") && openList[i].indexOf(contracts[i]) == -1) {
             open = false;
             break;
             }
             }*/
            return isOpen;
        } else {
            return;
        }
    });

    //  console.log("returnOpenArray=", returnOpenArray);
    //  console.log("returnArray=", returnArray);

    let combinedArray = [];
    combinedArray.push(returnArray);
    combinedArray.push(returnOpenArray);
    combinedArray.push(newSampleContract);
    //console.log("newSampleContract=", newSampleContract);
    //  console.log("ContractWeightedN_tuplets done.");
    return { alignerOptions: returnArray, openAlignerOptions: returnOpenArray, sampleContract: newSampleContract };
}

function isTickerInDatabase(ticker){
    try{
        let spread = tickerToGeneralForm(ticker).selected[0];
       // console.log("spread=", spread);
        return isSpreadInDatabase(spread);
    }
    catch(err){
        return false;
    }
}

function isSpreadInDatabase(spread) {
    // console.log("spread=", spread);
    let contracts = spread.replace(/\s/g, '').split('/');
    // console.log("contracts=", contracts);
    let filteredContracts = contracts.filter(function (x) {
        try {
            let commodity = contractNameDecomposer(x).commoditySymbol;
            // console.log("commodity=", commodity);
            if (!Contracts(commodity).includes(x)) {
                // console.log("bad contract.")
                return;
            } else {
                // console.log("OK.")
                return x;
            }
        } catch (err) {
            //  console.log("err=", err);
            console.log("error: ", x, " spread=", spread);
            return;
        }
    });
    //  console.log("filteredContracts=", filteredContracts);

    if (filteredContracts.length === contracts.length) {
        return true;
    } else {
        // console.log("bad contract.");
        return false;
    }
}

function Contracts(commodity, month) {
    // console.log("commodity=", commodity, "  month=", month);
    let monthsArray = commoditiesArray.find(x => x.symbol === commodity).months;
    let contracts = [];
    if (typeof month !== 'undefined') {
        contracts = monthsArray.find(x => x.symbol === month).contracts.map(x => x.ticker);
        //  console.log("contracts=", contracts);
    } else {
        // console.log("monthsArray=", monthsArray);
        monthsArray.map(x => x.symbol).forEach(function (month) {
            let monthContracts = monthsArray.find(x => x.symbol === month).contracts.map(x => x.ticker);
            // console.log("monthContracts=", monthContracts);
            contracts = contracts.concat(monthContracts);
        });
    }
    return contracts;
}

function ConstrainSampleContract(sampleContract) {
    console.log("Starting ConstrainSampleContract().");
    // console.trace();
    console.log("sampleContract =", sampleContract);
    let newSampleContract = sampleContract.map(contract => {
        let commodity = contractNameDecomposer(contract).commoditySymbol;
        let month = contractNameDecomposer(contract).monthSymbol;
        let contracts = Contracts(commodity, month);
        let openContracts = OpenContracts(commodity, month);
        // console.log("contracts=", contracts);

        let closedContracts = contracts.filter(x => !openContracts.includes(x));
        // console.log("closedContracts=", closedContracts);

        // let newContract = 
        return closedContracts[0];
    });

    /* let numberOfContractsOfSeparation = ContractSeparationCounter(contract[0], contract[1]);
     console.log("numberOfContractsOfSeparation =", numberOfContractsOfSeparation);
     
     let commodity = contractNameDecomposer(contract[0]).commoditySymbol;
     let month = contractNameDecomposer(contract[0]).monthSymbol;
     let year0 = contractNameDecomposer(contract[0]).year;
     
     let index = 0;
     let numberOfMonths = getCommoditiesArray().find(x => x.symbol === commodity).months.length;
     while (Math.abs(numberOfContractsOfSeparation) > numberOfMonths) {
     console.log("numberOfContractsOfSeparation =", numberOfContractsOfSeparation, " year0=", year0);
     if (numberOfContractsOfSeparation >= 0) {
     year0++;
     numberOfContractsOfSeparation = numberOfContractsOfSeparation - numberOfMonths;
     } else {
     year0--;
     numberOfContractsOfSeparation = numberOfContractsOfSeparation + numberOfMonths;
     }
     index++;
     if (index > 1000)
     break;
     }
     let newContract = commodity + year0 + month;
     contract[0] = newContract;
     */
    // console.log("newSampleContract=", newSampleContract);
    return newSampleContract;
}

function intraCommodityArrayTest(array) {
    let intracommodity = true;
    let commodity = contractNameDecomposer(array[0]).commoditySymbol;
    for (let i = 1; i < array.length; i++) {
        let tempCommodity = contractNameDecomposer(array[i]).commoditySymbol;
        if (tempCommodity !== commodity) {
            intracommodity = false;
            break;
        }
    }
    // console.log("intracommodity =", intracommodity);
    return intracommodity;
}

function ContractSeparationCounter(contract1, contract2) {
    // console.log("contract1=", contract1, " contract2=", contract2);
    let cND1 = contractNameDecomposer(contract1);
    let cND2 = contractNameDecomposer(contract2);
    let commodity1 = cND1.commoditySymbol;
    // let commodity2 = cND2.commoditySymbol;
    let month1 = cND1.monthSymbol;
    let month2 = cND2.monthSymbol;
    let year1 = cND1.year;
    let year2 = cND2.year;

    let months = getCommoditiesArray().find(x => x.symbol === commodity1).months.map(x => x.symbol);
    let numberOfMonths = months.length;
    // console.log("months =", months, " numberOfMonths=", numberOfMonths);

    let earlierContract;
    let laterContract;
    let counterSign = 1
    if (year1 < year2) {
        earlierContract = contract1;
        laterContract = contract2;
    } else if (year1 > year2) {
        earlierContract = contract2;
        laterContract = contract1;
        counterSign = -1;
    } else if (month1 < month2) {
        earlierContract = contract1;
        laterContract = contract2;
    } else {
        earlierContract = contract2;
        laterContract = contract1;
        counterSign = -1;
    }
    // console.log("earlierContract =", earlierContract);

    let counter = 0;
    let testContract = earlierContract;
    let testContractMonth = contractNameDecomposer(earlierContract).monthSymbol;
    let testContractMonthIndex = months.indexOf(testContractMonth);
    let testContractYear = contractNameDecomposer(earlierContract).year;
    while (testContract !== laterContract) {
        //  console.log("testContractMonth=", testContractMonth, " testContractMonthIndex=", testContractMonthIndex);
        if (testContractMonthIndex == numberOfMonths - 1) {
            testContractYear++;
            testContractMonthIndex = 0;
        } else {
            testContractMonthIndex++;
        }
        testContractMonth = months[testContractMonthIndex];

        testContract = commodity1 + testContractYear + testContractMonth;
        // console.log("testContract =", testContract);
        counter++;
        if (counter > 300)
            break;
    }
    // console.log("counter =", counter);
    return counterSign * counter;
}

function OpenSpreads(commodity, numberOfContractsApart) {
    let openContracts = OpenContracts(commodity);
    let numberOfMonths = getCommoditiesArray().find(x => x.symbol === commodity).months.length;
    console.log("openContracts=", openContracts, " numberOfContractsApart=", numberOfContractsApart, " numberOfMonths=", numberOfMonths);
    let spreads = [];
    for (let i = 0; i < numberOfMonths; i++) {
        if (i + numberOfContractsApart < openContracts.length) {
            let spread = parseInt(numberOfContractsApart) === 0 ? "" : "/" + openContracts[i + numberOfContractsApart];
            spread = openContracts[i] + spread;
            // console.log("spread=", spread);
            if (spread !== 'undefined') {
                spreads.push(spread);
            }
        }
    }
    return spreads;
}

function OpenContracts(commodity, month) {
    //console.log("commodity=", commodity, "  month=", month);
    let monthsArray = commoditiesArray.find(x => x.symbol === commodity).months;
    let contracts = [];
    if (typeof month !== 'undefined') {
        contracts = monthsArray.find(x => x.symbol === month).openContracts.map(x => x.ticker);
        // console.log("contracts=", contracts);
    } else {
        // console.log("monthsArray=", monthsArray);
        monthsArray.map(x => x.symbol).forEach(function (month) {
            let monthContracts = monthsArray.find(x => x.symbol === month).openContracts.map(x => x.ticker);
            // console.log("monthContracts=", monthContracts);
            contracts = contracts.concat(monthContracts);
        });
    }
    return contracts.sort();
}

function decode(text) {
    //  console.log("main.jsp decode():  text=" + text);
    let decodedText;
    if (text == '%') {
        decodedText = "%";
    } else {
        decodedText = decodeURIComponent(text);
        // console.log("decodedText=" + decodedText);
        // decodedText = decodedText.replace(/2F/g, '/');
        decodedText = decodedText.replace(/\+/g, ' ');
        // console.log("decodedText=" + decodedText);
    }
    return decodedText;
}

function getExpiration(contract) {
    // console.log("getExpiration() starting.");
    let commodity = contractNameDecomposer(contract).commoditySymbol;
    let month = contractNameDecomposer(contract).monthSymbol;
    let contractInCommoditiesObject = getCommoditiesArray().find(x => x.symbol === commodity).months
        .find(x => x.symbol === month).contracts
        .find(x => x.ticker === contract)

    // console.log("contractInCommoditiesObject=", contractInCommoditiesObject);
    return typeof contractInCommoditiesObject !== 'undefined' ? contractInCommoditiesObject.expiration : null;
}

function getSpreadExpiration(spread) {
    //  console.log("spread = ", spread);
    let contracts = spread.replace(/\s/g, '').split('/');
    let expiration = Math.min(...contracts.map(contract => getExpiration(contract)));
    return expiration === 0 ? null : expiration;
}

function spreadTitle(generalForm, invertDatesMultiplier = 1, noLegSwapping = false, symbols) {
   // console.log("spreadTitle() starting. symbols=", symbols);
    // console.trace();
    let form = JSON.parse(JSON.stringify(generalForm));
    // console.log("form=", form);

    let contract;
    if (form.instrument === "future") {
        if (typeof form.selected !== 'undefined' && form.selected.length > 0 && form.selected[0] !== null) {
            // console.log("form.selected=", form.selected);
            contract = form.selected[0].replace(/\s/g, '').split("/");
        } else {
            return null;
        }
    } else {
        contract = form.stockArray.slice(0, form.legs).map(x => x.split(',')[0].replace(/\s/g, ''));
    }

    let legs = contract.length;

    let mult = form.mult;
    let p = form.p;
    // console.log("p=" + p);

    if (p[0] < 0 && noLegSwapping === false) {
        let positivePositionIndex = 0;
        while (p[positivePositionIndex] < 0 && positivePositionIndex < legs) {
            positivePositionIndex++;
        }
        //  console.log("positivePositionIndex=", positivePositionIndex, " invertDatesMultiplier=", invertDatesMultiplier);
        if (positivePositionIndex < legs && invertDatesMultiplier == 1) {
            // console.log("Swapping legs.")
            p[0] = 1;
            p[positivePositionIndex] = -1;

            let temp = contract[0];
            contract[0] = contract[positivePositionIndex];
            contract[positivePositionIndex] = temp;

            temp = mult[0];
            mult[0] = mult[positivePositionIndex];
            mult[positivePositionIndex] = temp;
        } else if (legs !== 1) {
            for (let i = 0; i < legs; i++) {
                p[i] = -1 * p[i];
            }
        }
    }
    //console.log("p=" + p);

    let title = "";
    for (let i = 0; i < legs; i++) {
        let displayContract = (symbols === "old" || typeof symbols === "undefined") ? contract[i] : eodContractToBarchartContract(contract[i]).twoDigitYear;
        let position = "+";
        if (p[i] == -1) {
            position = "-";
        }
        // console.log("position=" + position);

        if (mult[i] == 1) {
            title = title + position + (i === 0 ? "" : " ") + displayContract + " ";
        } else {
            title = title + position + (i === 0 ? "" : " ") + mult[i] + "*" + displayContract + " ";
        }
    }
    // console.log(title[0]);
    if (title[0] === '+') {
        title = title.substr(1);
    }
    return title.trim();
}

function spreadTitleLegs(generalForm, invertDatesMultiplier = 1, noLegSwapping = false, symbols) {
   // console.log("spreadTitleLegs() starting. symbols=", symbols);
    // console.trace();
    let form = JSON.parse(JSON.stringify(generalForm));
    // console.log("form=", form);

    let contract;
    if (form.instrument === "future") {
        if (typeof form.selected !== 'undefined' && form.selected.length > 0) {
            contract = form.selected[0].split("/");
        } else {
            return null;
        }
    } else {
        contract = form.stockArray.slice(0, form.legs).map(x => x.split(',')[0].replace(/\s/g, ''));
    }
    // console.log("contract=", contract);

    let legs = contract.length;

    let mult = form.mult;
    let p = form.p;
    // console.log("p=" + p);

    if (p[0] < 0 && noLegSwapping === false) {
        let positivePositionIndex = 0;
        while (p[positivePositionIndex] < 0 && positivePositionIndex < legs) {
            positivePositionIndex++;
        }
        //  console.log("positivePositionIndex=", positivePositionIndex, " invertDatesMultiplier=", invertDatesMultiplier);
        if (positivePositionIndex < legs && invertDatesMultiplier == 1) {
            // console.log("Swapping legs.")
            p[0] = 1;
            p[positivePositionIndex] = -1;

            let temp = contract[0];
            contract[0] = contract[positivePositionIndex];
            contract[positivePositionIndex] = temp;

            temp = mult[0];
            mult[0] = mult[positivePositionIndex];
            mult[positivePositionIndex] = temp;
        } else if (legs !== 1) {
            for (let i = 0; i < legs; i++) {
                p[i] = -1 * p[i];
            }
        }
    }
    //console.log("p=" + p);

   // let title = "";
    let tickerLegs = [];
    for (let i = 0; i < legs; i++) {
        let displayContract = (symbols === "old" || typeof symbols === "undefined") ? contract[i] : eodContractToBarchartContract(contract[i]).twoDigitYear;
        if (contract[i].indexOf("--") === -1) {
            let position = "+";
            if (p[i] == -1) {
                position = "-";
            }
            // console.log("position=" + position);

            if (mult[i] == 1) {
               // title = title + position + " " + displayContract + " ";
                tickerLegs[i] = position + "" + displayContract;
            } else {
              //  title = title + position + " " + mult[i] + "*" + displayContract + " ";
                tickerLegs[i] = position + "" + mult[i] + "*" + displayContract;
            }
        } else {
            tickerLegs[i] = displayContract;
        }
    }
    // console.log(title[0]);
    /*   if (title[0] === '+') {
     title = title.substr(1);
     }*/
    //  console.log("tickerLegs=" + tickerLegs);
    return tickerLegs;
}

function FrontMonthContract(commodity) {
    let contracts = OpenContracts(commodity);
    return contracts[0];
}

function omit(obj, omitKey) {
    return Object.keys(obj).reduce((result, key) => {
        if (key !== omitKey) {
            result[key] = obj[key];
        }
        return result;
    }, {});
}

function commodityName(symbol) {
    return commoditiesArray.find(x => x.symbol === symbol).name;
}

function commodityMonths(commodity) {
    return getCommoditiesArray().find(x => x.symbol === commodity).months.map(x => x.symbol);
}

function contractDigits(contract, instrument = "future") {
    //console.log("contract=" + contract);
    let digits;
    if (instrument == "future") {
        let commodity = contractNameDecomposer(contract).commoditySymbol;
        // console.log("commoditySymbol=" + commoditySymbol);
        digits = getCommoditiesArray().find(x => x.symbol === commodity).digits;
    } else {
        digits = 2;
    }
    return digits;
}

function spreadDigits(spread, instrument = "future") {
    //  console.log("spreadDigits() starting.");
    let digits;
    if (instrument !== "future") {
        digits = 2;
    } else {
        let sameUnitsAndUnitMoves = areSameUnitsAndUnitMoves(spread);
        // console.log("sameUnitsAndUnitMoves =", sameUnitsAndUnitMoves);
        if (sameUnitsAndUnitMoves) {
            let contracts = spread.replace(/\s/g, '').split('/');
            digits = contractDigits(contracts[0]);
        } else {
            digits = 2;
        }
    }
    return digits;
}

function heightControl(operation, chart) {
    // console.log("am4charts.heightControl() starting.");
    //  console.log("operation=", operation);
    let height = $("#chartDiv").height();
    // console.log("height before adding axis=", height);

    let nominalNumberOfAxes = 0.13 * chart.yAxes.length + 3.5;
    let shim = 0.68;
    // console.log("nominalNumberOfAxes=", nominalNumberOfAxes);

    if (operation === "adding") {
        $("#chartDiv").height(height * (nominalNumberOfAxes + shim) / (nominalNumberOfAxes));
        // console.log("height after adding axis=", $("#chartDiv").height());
        let axisPercentage = 100 / (nominalNumberOfAxes);
        //  console.log("axisPercentage=", axisPercentage);
        return axisPercentage;
    } else {
        $("#chartDiv").height(height * (nominalNumberOfAxes) / (nominalNumberOfAxes + shim));
        //  console.log("height after adding axis=", $("#chartDiv").height());
    }
}

function removeNaNs(array, valueFieldName) {
    // console.log("removeNaNs() starting."); // This also removes weekends and days without data.
    //  console.log("valueFieldName=", valueFieldName);
    // console.log("array=", array);
    let fields = Object.keys(array[0]);
    //  console.log("fields=", fields);
    if (typeof valueFieldName === 'undefined' || fields.indexOf(valueFieldName) === -1) {
        valueFieldName = "close";
    }
    // console.log("valueFieldName=", valueFieldName);

    let returnArray = array.filter(x => !isNaN(x[valueFieldName]));
    //  let nAnArray = array.filter(x => isNaN(x[valueFieldName]));
    // console.log("nAnArray=", nAnArray);
    // console.log("returnArray=", returnArray);
    return returnArray;
}

function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

function getFND(contract) {
    // console.log("contract = ", contract);
    let commodity = contractNameDecomposer(contract).commoditySymbol;
    let month = contractNameDecomposer(contract).monthSymbol;
    let contractObject = getCommoditiesArray().find(x => x.symbol === commodity).months
        .find(x => x.symbol === month).contracts
        .find(x => x.ticker === contract)

    // console.log("contractObject = ", contractObject);
    let contractFND;
    if (typeof contractObject !== 'undefined') {
        contractFND = contractObject.fnd;
        // console.log("contractFND = ", contractFND);
    }
    return contractFND;
}

function getSpreadFND(spread) {
    // console.log("getSpreadFND() starting. spread=", spread);
    let contracts = spread.replace(/\s/g, '').split('/');
    return Math.min(...contracts.map(contract => getFND(contract)));
}

function tickerToGeneralForm(ticker, instrument = "future") {
    // console.log("tickerToGeneralForm() starting. ticker=", ticker);
    // console.trace();
    // console.log("instrument=", instrument);
    let formmattedTicker = ticker.replace(/\s/g, '');
    //  console.log("formmattedTicker=", formmattedTicker);

    let tickerLegs = formmattedTicker.split(/[.+-/]/);
    if (tickerLegs[0] === '') {
        tickerLegs.shift();
    }
    // console.log("tickerLegs=", tickerLegs);

    let legs = tickerLegs.length;

    let mult = Object.assign([1, 1, 1, 1], tickerLegs.map(x => x.includes('*') ? parseInt(x.split('*')[0]) : 1));
    // console.log("mult=", mult);

    let arr = [...ticker].filter(x => ['-', '+'].includes(x));
    //  console.log("arr=", arr);
    if (arr.length < legs) {
        arr.unshift('+');
    }
    let p = Object.assign([1, 1, 1, 1], arr.map(x => x === '+' ? 1 : -1));
    //  console.log("p=", p);

    let generalForm = {};
    generalForm.legs = legs;
    generalForm.mult = mult;
    generalForm.p = p;
    let selected = [tickerLegs.map(x => {
        let returnValue = x;
        if (x.includes('*')) {
            // console.log("includes *: ", x);
            returnValue = x.split('*')[1];
        }
        // console.log("returnValue=", returnValue);
        return returnValue.replace(/\s/g, '');
    }).join('/')];

    generalForm.selected = selected;
    generalForm.instrument = instrument;

    if (instrument === "future") {
        let contracts = generalForm.selected[0].split('/');
        //console.log("contracts=", contracts);
        let commodity = contractNameDecomposer(contracts[0]).commoditySymbol;
        let openContracts = OpenContracts(commodity).filter(x => !contracts.includes(x)).sort();
        // console.log("openContracts=", openContracts);

        let contractForPadding = openContracts.slice(contracts.length, 4);
        // console.log("contractForPadding=", contractForPadding);

        generalForm.sampleContract = [...contracts, ...contractForPadding];
        generalForm.unitMove = generalForm.sampleContract.map(contract => getUnitMove(contractNameDecomposer(contract).commoditySymbol));
    } else {
        generalForm.symbolArray = generalForm.selected[0].split('/');
        generalForm.unitMove = [1, 1, 1, 1];
    }
    //console.log("generalForm=", generalForm);
    return generalForm;
}

function getExchange(commodity) {
    let group = getGroup(commodity);
    // console.log("group=", group);

    switch (group) {
        case "Grains":
            if (commodity == "KW") {
                return "KCBT";
            }
            if (commodity == "MW") {
                return "MGEX";
            } else {
                return "CBOT";
            }
        case "Energies":
            return "NYMEX";
        case "Metals":
            if (["PA", "PL"].includes(commodity)) {
                return "NYMEX";
            } else {
                return "COMEX";
            }
        case "Interest Rates":
            if (commodity == "ED") {
                return "CME";
            } else {
                return "CBOT";
            }
        case "Currencies":
            if (["EC", "DX"].includes(commodity)) {
                return "ICEUS";
            } else {
                return "CME";
            }
        case "Softs and Fibers":
            if (commodity == "SW") {
                return "LCE";
            }
            if (["CB", "LB"].includes(commodity)) {
                return "CME";
            } else {
                return "ICEUS";
            }
        case "Indices":
            if (commodity == "VX") {
                return "CFE";
            }
            if (["NQ", "SP"].includes(commodity)) {
                return "GBLX";
            } else {
                return "CBOTM";
            }
        case "Meats":
            return "CME";
    }
}

function isContractOpen(contract) {
    let commodity = contractNameDecomposer(contract).commoditySymbol;
    let month = contractNameDecomposer(contract).monthSymbol;
    return OpenContracts(commodity, month).includes(contract);
}

function isSpreadOpen(spread) {
    // console.log("isSpreadOpen() starting. spread=", spread);
    if (spread === "seasonal") {
        return false;
    } else {
        let contracts = spread.replace(/\s/g, '').split('+').join('/').split('-').join('/').split('/');
        // console.log("contracts=", contracts);
        return !contracts.some(x => !isContractOpen(x));
    }
}

let groupsObject = {
    "Currencies": { "commodities": ["AD", "BP", "CD", "EC", "JY", "SF", "DX"], "name": "Currencies" },
    "Energies": { "commodities": ["B", "CL", "GO", "RB", "HO", "NG"], "name": "Energies" },
    "Grains": { "commodities": ["C", "KW", "MW", "O", "SM", "BO", "S", "W", "RR"], "name": "Grains" },
    "Indices": { "commodities": ["YM", "NQ", "SP", "VX"], "name": "Indices" },
    "Interest Rates": { "commodities": ["TY", "TU", "US", "FV", "ED", "ZQ"], "name": "Interest Rates" },
    "Meats": { "commodities": ["FC", "LH", "LC"], "name": "Meats" },
    "Metals": { "commodities": ["HG", "GC", "PA", "PL", "SI"], "name": "Metals" },
    "Softs and Fibers": { "commodities": ["CB", "CSC", "DC", "CC", "KC", "CT", "LB", "OJ", "RC", "SB", "SW"], "name": "Softs and Fibers" }
};

function getGroup(commodity) {
    let returnGroup = "";
    let groups = Object.keys(groupsObject);
    groups.forEach(function (group) {
        let commodities = groupsObject[group].commodities;
        // console.log(group + ": " + commodities);
        let isInGroup = commodities.includes(commodity);
        if (isInGroup) {
            returnGroup = group;
        }

    });
    return returnGroup;
}

function contractUnits(contract) {
    // console.log("contractUnits() starting. contract=" + contract);
    let commodity = contractNameDecomposer(contract).commoditySymbol;
    return getCommoditiesArray().find(x => x.symbol === commodity).units;
}

function spreadUnits(spread, instrument = "future") {
    // console.log("spreadUnits() starting. spread=" + spread);
    let units;
    if (instrument !== "future") {
        units = "USD";
    } else {
        let sameUnitsAndUnitMoves = areSameUnitsAndUnitMoves(spread);
        // console.log("sameUnitsAndUnitMoves =", sameUnitsAndUnitMoves);
        if (sameUnitsAndUnitMoves) {
            let contracts = spread.replace(/\s/g, '').split('/');
            units = contractUnits(contracts[0]);
        } else {
            units = "USD";
        }
    }
    return units;
}

function getDaysArray(start, end, timePeriod) {
    console.log("start=", start, " end=", end, " timePeriod=", timePeriod);
    let arr = [];
    for (let dt = moment(start).add(-1, timePeriod); dt <= end; dt.add(1, timePeriod)) {
        arr.push(moment(dt));
    }
    return arr;
}

function getBarchartMultsAndPositionsArray(legs, spreadP = 1) {
    console.log("getBarchartMultsAndPositionsArray() starting. spreadP=", spreadP);
    // console.log("barchartMultsAndPositionsMatrix =", barchartMultsAndPositionsMatrix);
    let barchartMultsAndPositionsMatrix = [
        { legs: 1, p: [1, -1, 1, 1], mult: [1, 1, 1, 1] },
        { legs: 2, p: [1, -1, 1, 1], mult: [1, 1, 1, 1] },
        { legs: 3, p: [1, -1, 1, 1], mult: [1, 2, 1, 1] },
        { legs: 4, p: [1, -1, -1, 1], mult: [1, 1, 1, 1] }
    ];

    let barchartMultsAndPositionsArray = barchartMultsAndPositionsMatrix.find(x => x.legs === legs);
    console.log("barchartMultsAndPositionsArray=", barchartMultsAndPositionsArray);
    barchartMultsAndPositionsArray.p.forEach((p, index, arr) => {
        arr[index] = p * spreadP;
        console.log("p=", p, "arr=", arr);
    });
    console.log("barchartMultsAndPositionsArray=", barchartMultsAndPositionsArray);
    return barchartMultsAndPositionsArray;
}

function hexToRgb(hex) {
    let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
    } : null;
}

function transpose(a) {
    return Object.keys(a[0]).map(function (c) {
        return a.map(function (r) {
            return r[c];
        });
    });
}

function orderContracts(contract1, contract2) {
    //  console.log("contract1=", contract1, " contract2=", contract2);
    let cND = contractNameDecomposer(contract1);
    let month1 = cND.monthSymbol;
    let month2 = contractNameDecomposer(contract2).monthSymbol;
    let year1 = cND.year;
    let year2 = contractNameDecomposer(contract2).year;

    let earlierContract;
    let laterContract;
    if (year1 < year2) {
        earlierContract = contract1;
        laterContract = contract2;
    } else if (year1 > year2) {
        earlierContract = contract2;
        laterContract = contract1;
    } else if (month1 < month2) {
        earlierContract = contract1;
        laterContract = contract2;
    } else {
        earlierContract = contract2;
        laterContract = contract1;
    }
    return [earlierContract, laterContract];
}

const kebabize = str => {
    return str.split('').map((letter, idx) => {
        return letter.toUpperCase() === letter
            ? `${idx !== 0 ? '-' : ''}${letter.toLowerCase()}`
            : letter;
    }).join('');
}

function bfs(tree, key, collection) {
    if (!tree[key] || tree[key].length === 0)
        return;
    for (var i = 0; i < tree[key].length; i++) {
        var child = tree[key][i]
        collection[child.id] = child;
        bfs(child, key, collection);
    }
    return;
}

function monthToColor(month) {
    let colors = {
        F: "#FFD700",
        G: "#FFA500",
        H: "#D2691E",
        J: "#FF0000",
        K: "#FF1493",
        M: "#800080",
        N: "#8A2BE2",
        Q: "#0000FF",
        U: "#1E90FF",
        V: "#00CED1",
        X: "#008080",
        Z: "#9ACD32"
    };
    return colors[month];
}

function yearToColor(year) {
    let colors = ["#0000FF", "#1E90FF", "#00CED1", "#008080", "#9ACD32", "#FFA500", "#D2691E", "#FF0000", "#800080", "#8A2BE2"];

    let yearString = String(year);
    // console.log("yearString=", yearString);

    let currentYear = moment().format("YYYY");
    // console.log("currentYear=", currentYear);

    let lastDigitOfYear = yearString.substring(3);
    let lastDigitOfCurrentYear = currentYear.substring(3);
    // console.log("lastDigitOfYear=", lastDigitOfYear, " lastDigitOfCurrentYear=", lastDigitOfCurrentYear);

    let index = lastDigitOfYear - lastDigitOfCurrentYear - 3;
    if (index < 0) {
        index = index + 10;
    }

    return colors[index];
}

function spreadToColor(spread) {
    //.console.log("spreadToColor() starting. spread=", spread);
    if (spread === "seasonal") {
        return "black";
    } else {
        let contract = spread.replace(/\s/g, '').split('/')[0];
        // console.log("contract=", contract);

        let year = contractNameDecomposer(contract).year;
        // console.log("year=", year);
        return yearToColor(year);
    }
}

function changeCommodityLeg(commodity, index, generalForm) {
    console.log("changeCommodityLeg() starting. commodity=", commodity, " index=", index, " generalForm=", JSON.parse(JSON.stringify(generalForm)));
    let months = getCommoditiesArray().find(x => x.symbol === commodity).months.map(x => x.symbol);
    // console.log("months =", months);
    let year = contractNameDecomposer(generalForm.sampleContract[0]).year;
    let newUnitMove = getCommoditiesArray().find(x => x.symbol === commodity).unitMove;
    //  console.log("year =", year);
    //  console.log("newUnitMove =", newUnitMove);

    let newSampleContractArray = [];
    let newUnitMoveArray = [];
    let newContract;
    console.log("generalForm.intracommodity =", generalForm.intracommodity);
    if (JSON.parse(generalForm.intracommodity) === false) {
        newSampleContractArray = generalForm.sampleContract.slice(0);
        newContract = commodity + year + months[index];
        // console.log("newContract =", newContract);
        if (!isSpreadInDatabase(newContract)) {
            newContract = decrementSampleContract([newContract])[0];
        }
        //  console.log("newContract =", newContract);
        newSampleContractArray[index] = newContract;

        if (typeof generalForm.unitMove !== 'undefined') {
            newUnitMoveArray = generalForm.unitMove.slice(0);
        } else {
            newUnitMoveArray = newSampleContractArray.slice(0).map(x => getUnitMove(contractNameDecomposer(x).commoditySymbol));
        }
        newUnitMoveArray[index] = newUnitMove;
    } else {
        let currentCommodity = contractNameDecomposer(generalForm.sampleContract[0]).commoditySymbol;
        // console.log("currentCommodity =", currentCommodity);
        let startIndex;
        if (currentCommodity === commodity) {
            let allContracts = allCommodityContracts(commodity);
            let listIndex = allContracts.indexOf(generalForm.sampleContract[0]);
            newSampleContractArray = allContracts.slice(listIndex, listIndex + 4);
            console.log("newSampleContractArray=", newSampleContractArray);

            if (newSampleContractArray.length < 4) {
                listIndex = allContracts.indexOf(incrementContract(generalForm.sampleContract[0], -1));
                newSampleContractArray = allContracts.slice(listIndex, listIndex + 4);
                console.log("newSampleContractArray=", newSampleContractArray);
            }

        } else {
            startIndex = 0;
        }
        //console.log("startIndex =", startIndex);

        for (let i = startIndex; i < 4; i++) {
            newContract = commodity + year + months[i];
            if (!isSpreadInDatabase(newContract)) {
                newContract = decrementSampleContract([newContract])[0];
            }
            // console.log("newContract =", newContract);
            newSampleContractArray.push(newContract);
        }
        newUnitMoveArray = newSampleContractArray.slice(0).map(x => getUnitMove(contractNameDecomposer(x).commoditySymbol));
    }
    console.log("newSampleContractArray =", newSampleContractArray);
    console.log("newUnitMoveArray =", newUnitMoveArray);
    generalForm.sampleContract = newSampleContractArray;
    generalForm.unitMove = newUnitMoveArray;
    generalForm.selected = [];
    generalForm.numberOfContractsApart = 0;
    generalForm.constrainSampleContract = false;
    return generalForm;
}

function allCommodityContracts(commodity) {
    let months = getCommoditiesArray().find(x => x.symbol === commodity).months;
    let allContracts = months.reduce((arr, month) => {
        arr.push(...month.contracts.map(x => x.ticker));
        return arr;
    }, []).sort();
    //  console.log("allContracts=", allContracts);
    return allContracts;
}

function nearestOpenSpreadOrLatestClosedSpread(generalForm) {
    let openTickers = listOpenTickerOptions(generalForm);
    if (openTickers.length > 0) {
        return openTickers[openTickers.length - 1];
    } else {
        return listTickerOptions(generalForm)[0];
    }
}

function nearestOpenSpread(generalForm) {
    let openTickers = listOpenTickerOptions(generalForm);
    if (openTickers.length > 0) {
        return openTickers[openTickers.length - 1];
    } else {
        return { value: null, text: "no open spreads" };
    }
}

function listTickerOptions(generalForm, symbols) {
    //  console.log("listOptions() starting. generalForm=", generalForm");

    return listOptions(generalForm).map(x => {
        // console.log("x=", x);
        generalForm.selected = [x.value];
        let invertDatesMultiplier = 1;
        let noLegSwapping = true;
        let ticker = spreadTitle(JSON.parse(JSON.stringify(generalForm)), invertDatesMultiplier, noLegSwapping, symbols);
        // console.log("ticker=", ticker);

        return { value: x.value, text: ticker };
    });
}

function listOpenTickerOptions(generalForm) {
    //  console.log("listOptions() starting. generalForm=", generalForm");

    return listOptions(generalForm).filter(x => x.open).map(x => {
        // console.log("x=", x);
        generalForm.selected = [x.value];
        let invertDatesMultiplier = 1;
        let noLegSwapping = true;
        let ticker = spreadTitle(JSON.parse(JSON.stringify(generalForm)), invertDatesMultiplier, noLegSwapping);
        // console.log("ticker=", ticker);

        return { value: x.value, text: ticker };
    });
}

function listClosedTickerOptions(generalForm) {
    //  console.log("listOptions() starting. generalForm=", generalForm");

    return listOptions(generalForm).filter(x => !x.open).map(x => {
        // console.log("x=", x);
        generalForm.selected = [x.value];
        let invertDatesMultiplier = 1;
        let noLegSwapping = true;
        let ticker = spreadTitle(JSON.parse(JSON.stringify(generalForm)), invertDatesMultiplier, noLegSwapping);
        // console.log("ticker=", ticker);

        return { value: x.value, text: ticker };
    });
}

function listOptions(generalForm) {
    let returnArray = [];
    //  let alignerOpenOptions = state.browserSideOnly.alignerOpenOptions;
    //  console.log("alignerOpenOptions=", alignerOpenOptions)

    let alignerOpenSpreads = generalForm.browserSideOnly.alignerOpenOptions.map(x => x.join('/'));
   // console.log("alignerOpenSpreads=", alignerOpenSpreads)

    generalForm.browserSideOnly.alignerOptions.map(optionArray => {
        let optionSpread = optionArray.join('/');
        // console.log("optionSpread=", optionSpread)
        if (optionSpread.indexOf("-") == -1) {
            // console.log("optionArray=", optionArray)
            let open = alignerOpenSpreads.includes(optionSpread); /*&& ["seasonal", "history"].includes(state.study); */
            returnArray.push({ value: optionSpread, open: open });
        }
    });
    return returnArray;
}

function programCompositionArray(role){
    let returnarray = [
     { input: basicChartInput, initialState: basicChartInitialState, value: 'BasicCharts', text: 'Futures Charts' },
     { input: openSpreadsInput, initialState: openSpreadsInitialState, value: 'OpenSpreads', text: 'Open Spreads' },
     { input: stockChartInput, initialState: stockChartInitialState, value: 'StockCharts', text: 'Stock Charts' },
     { input: calculatorInput, initialState: calculatorInitialState, value: 'Calculator', text: 'Calculator' },
     { input: forwardCurvesInput, initialState: forwardCurvesInitialState, value: 'ForwardCurves', text: 'Forward Curves' },
     { input: searchInput, initialState: searchInitialState, value: 'Search', text: 'Search' },
     { input: tradeMapsInput, initialState: tradeMapsInitialState, value: 'TradeMaps', text: 'Trade Maps' },
     { input: longTermChartsInput, initialState: longTermChartsInitialState, value: 'LongTermCharts', text: 'Long Term Charts' },
     { input: historyInput, initialState: historyInitialState, value: 'History', text: 'History' }
    ];

    if (['admin', 'superadmin'].includes(role)) {
        returnarray.push({ input: spreadCoverageInput, initialState: spreadCoverageInitialState,  value: 'SpreadCoverage', text: 'Spread Coverage' });        
        returnarray.push({ input: timeSeriesInput, initialState: timeSeriesChartInitialState, value: 'TimeSeriesCharts', text: 'Time Series Charts' });
        returnarray.push({ input: firestoreSearchInput, initialState: firestoreSearchInitialState, value: 'FirestoreSearch', text: 'Firestore Search' });
        returnarray.push({ input: watchListInput, initialState: watchListInitialState, value: 'WatchList', text: 'Watch List' });
        returnarray.push({ input: tradeExplorerInput, initialState: tradeExplorerInitialState, value: 'TradeExplorer', text: 'Trade Explorer' });
    }
    return returnarray.sort((a, b) => (a.text > b.text) ? 1 : -1);
}

function getProgramComposition(program, role) {
   // console.log("program=", program, " role=", role);
    let returnObject = programCompositionArray(role).find(x => x.value === program);
   // console.log("returnObject=", returnObject);
    return returnObject;
}

let storeTab = _.debounce(function (form, email) {
   // console.log("storeTab() starting. email=", email);
    // console.log("this.activeModuleJson=", this.activeModuleJson);
    // console.log("this.activeModuleName=", this.activeModuleName);
    let generalForm = JSON.parse(JSON.stringify(form));
    if (generalForm !== null) {
     
      delete generalForm.browserSideOnly;
      // delete generalForm.playback;
     // console.log("generalForm.moduleName=", generalForm.moduleName);

      if (typeof generalForm.moduleName !== "undefined") {
        fb.db
          .collection("tabs")
          .doc(email)
          .collection("tabs")
          .doc(generalForm.moduleName)
          .set(generalForm)
          .then(() => {
            console.log("tab saved.");
          });

          fb.db
          .collection("tabs")
          .doc(email)
          .collection("activeModuleName")
          .doc("activeModuleName-document")
          .set({"activeModuleName": generalForm.moduleName})
          .then(() => {
           // console.log("activeModuleName saved.");
          });
      }
    }
  }, 500);

export {
    areSameUnits, areSameUnitMoves, areSameUnitsAndUnitMoves, commodityUnits,
    contractNameDecomposer, getUnitMove, eod_to_barchart_conv, barchartSpreadToEodSpread, eodTickerToYahooTickerLegs,
    barchartContractToEodContract, incrementContract, decrementSampleContract, ContractWeightedN_tuplets, isSpreadInDatabase, isTickerInDatabase,
    ConstrainSampleContract, intraCommodityArrayTest, ContractSeparationCounter, Contracts, OpenContracts, OpenSpreads, setCommoditiesArray,
    getCommoditiesArray, decode, getSpreadExpiration, spreadTitle, spreadTitleLegs, FrontMonthContract, omit, commodityName,
    spreadDigits, heightControl, removeNaNs, capitalizeFirstLetter, getSpreadFND, tickerToGeneralForm, getExchange, spreadUnits,
    isSpreadOpen, generalFormToBarchartTicker, getDaysArray, getBarchartMultsAndPositionsArray, hexToRgb, commodityMonths,
    eodContractToBarchartContract, eodContractToYahooContract, transpose, orderContracts, kebabize, bfs, contractDigits, groupsObject,
    getGroup, yearToColor, spreadToColor, monthToColor, changeCommodityLeg, allCommodityContracts, createContractLists, listOptions,
    listTickerOptions, listOpenTickerOptions, listClosedTickerOptions, nearestOpenSpread, nearestOpenSpreadOrLatestClosedSpread,
    getProgramComposition, programCompositionArray, storeTab, displayCommodity
};
