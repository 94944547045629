<template>
    <span>                                                         

        <b-form-select style="border:1px solid gray; color: black; font-size: 11px;  border-radius: 3px;"
                       v-model="selected" :options="listTickerOptionsNoLegSwapping"
                       size="sm"></b-form-select>

    </span>
</template>

<script>
    import {listTickerOptions} from "../js/main";

    export default {
        mounted() {
            // console.log("contract-selector-single-select.vue beforeMount() starting.)
        },
        props: ['namespace'],
        computed: {
            listTickerOptionsNoLegSwapping() {
                let generalForm = JSON.parse(JSON.stringify(this.$store.state[this.namespace]));
                let options = listTickerOptions(generalForm, this.$store.state.user.symbols);
               // console.log("listTickerOptions=", options);
                return options;
            },
            selected: {
                get() {
                    let selected = this.$store.state[this.namespace].selected;
                   // console.log("selected=", [...selected]);
                    return selected[0];
                },
                set(selected) {
                    console.log("selected set() starting. selected=", selected);
                    this.$store.commit(this.namespace + '/setSelected', [selected]);
                }
            }
        }
    }
</script>
