//import moment from "moment";
//import {tickerToGeneralForm} from '../js/main';

export default {
    namespaced: true,
    state: () => ({
            sortParameter: null,
            legs: null,
            position: null,
            direction: null,
            source: null,
            minDays: null,
            maxDays: null,
            userConditions: [],
            query: null,
            openStart: null,
            openEnd: null,
            closeStart: null,
            closeEnd: null,
            tradePeriodExcludedSelector: null
        }),
    mutations: {
        setSortParameter: function (state, sortParameter) {
            state.sortParameter = sortParameter;
        },
        setLegs: function (state, legs) {
            state.legs = legs;
        },
        setPosition: function (state, position) {
            state.position = position;
        },
        setDirection: function (state, direction) {
            state.direction = direction;
        },
        setSource: function (state, source) {
            state.source = source;
        },
        setMinDays: function (state, minDays) {
            state.minDays = minDays;
        },
        setMaxDays: function (state, maxDays) {
            state.maxDays = maxDays;
        },
        setUserConditions: function (state, userConditions) {
            state.userConditions = userConditions;
        },
        setQuery: function (state, query) {
            state.query = query;
        },
        setOpenStart: function (state, openStart) {
            console.log("openStart=", openStart);
            state.openStart = openStart;
        },
        setOpenEnd: function (state, openEnd) {
            console.log("openEnd=", openEnd);
            state.openEnd = openEnd;
        },
        setCloseStart: function (state, closeStart) {
            console.log("closeStart=", closeStart);
            state.closeStart = closeStart;
        },
        setCloseEnd: function (state, closeEnd) {
            console.log("closeEnd=", closeEnd);
            state.closeEnd = closeEnd;
        }, 
        setTradePeriodExcludedSelector: function (state, tradePeriodExcludedSelector) {
            state.tradePeriodExcludedSelector = tradePeriodExcludedSelector;
        },
    }
};


