import {heightControl, removeNaNs, spreadDigits} from '../main';
import {md} from '../am4charts-plugins/am4charts.main';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";

function typicalPrice(objectArray, cciSource, legs) {
    // console.log("typicalPrice() starting.");
    // console.log("objectArray=", objectArray);
    // console.log("cciSource=", cciSource);
    for (var j = 0; j < objectArray.length; j++) {
        let obj = objectArray[j];
        let typical = (obj.high + obj.low + obj.close) / 3;
        obj["typical"] = (cciSource === "typical" && legs == 1) ? typical : obj.close;
    }
}

function cci(objectArray, period) {
    // console.log("cci() starting.");
   // console.log("objectArray[objectArray.length - 1]=", JSON.parse(JSON.stringify(objectArray[objectArray.length - 1])));

    let filteredKeys;
    md(objectArray, period, "typical");
   // console.log("objectArray=", JSON.parse(JSON.stringify(objectArray)));

    objectArray.forEach(x => {
        let cci = (x.typical - x["sma_" + period]) / (0.015 * x["typical_md_" + period]);
        if (!isNaN(cci) && isFinite(Math.abs(cci))) {
            x.cci = cci;
        }

        let keys = Object.keys(x);
        //console.log("keys=", keys);
        filteredKeys = keys.filter(x => x.includes("typical"));
        //console.log("filteredKeys=", filteredKeys);
        filteredKeys.forEach(key => {
            delete x[key];
        });
        delete x["sma_" + period];
    });
   // console.log("objectArray=", objectArray);
    return objectArray;
}

function removeCciPanel(chart) {
  //  console.log("removeCciPanel() starting.");
    chart.series.removeIndex(chart.series.indexOf(chart.map.getKey("cci-series"))).dispose();
    chart.yAxes.removeIndex(chart.yAxes.indexOf(chart.map.getKey("cci-axis"))).dispose();

    heightControl("removing", chart);
}

function createCciGuides(chart) {
    let valueAxis = chart.map.getKey("cci-axis");

    var lowerRange = valueAxis.axisRanges.create();
    lowerRange.value = -100;
    lowerRange.grid.stroke = "#505050";
    lowerRange.grid.strokeWidth = 1;
    lowerRange.grid.strokeDasharray = "2,2";
    lowerRange.grid.strokeOpacity = 1;
    lowerRange.label.inside = true;
    lowerRange.label.text = "-100";
    lowerRange.label.fill = "#505050";
    lowerRange.label.align = "right";
    lowerRange.label.verticalCenter = "bottom";

    var upperRange = valueAxis.axisRanges.create();
    upperRange.value = 100;
    upperRange.grid.stroke = "#505050";
    upperRange.grid.strokeWidth = 1;
    upperRange.grid.strokeDasharray = "2,2";
    upperRange.grid.strokeOpacity = 1;
    upperRange.label.text = "100";
    upperRange.label.fill = "#505050";
    upperRange.label.align = "right";
    upperRange.label.verticalCenter = "bottom";
}

function addCciPanel(chart, generalForm) {
  //  console.log("AddCciPanel() starting.");
    let digits = spreadDigits(generalForm.selected[0], generalForm.instrument);
   // console.log("digits=", digits);
    let formatString = "#." + "0".repeat(digits);

    let mainData = chart.map.getKey("main-series").data;
    typicalPrice(removeNaNs(mainData), generalForm.cciSource, generalForm.legs);
    let cciData = cci(mainData, generalForm.cciPeriod);

    let valueAxis = chart.map.getKey("cci-axis");
    let series = chart.map.getKey("cci-series");
    var alreadyPresent = typeof valueAxis !== 'undefined';
  //  console.log("alreadyPresent=", alreadyPresent);

    if (!alreadyPresent) {
        valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        let axisPercentage = heightControl("adding", chart);
        valueAxis.height = am4core.percent(axisPercentage);

        series = chart.series.push(new am4charts.LineSeries());
    }

    valueAxis.id = "cci-axis";
    valueAxis.tooltip.disabled = true;
    valueAxis.zIndex = 3
// this makes gap between panels
    valueAxis.marginTop = 16;
    valueAxis.renderer.baseGrid.disabled = true;
    valueAxis.renderer.inside = true;
    valueAxis.renderer.labels.template.verticalCenter = "bottom";
    valueAxis.renderer.labels.template.padding(2, 2, 2, 2);
//valueAxis.renderer.maxLabelPosition = 0.95;
    valueAxis.renderer.fontSize = "0.8em"
    valueAxis.renderer.gridContainer.background.fillOpacity = 0.05;
    valueAxis.title.text = generalForm.cciPeriod + "-day CCI";
    valueAxis.title.fontWeight = "bold";
    valueAxis.zoomable = false;

    series.id = "cci-series";
    series.data = cciData;
    series.dataFields.dateX = "date";
    series.clustered = false;
    series.dataFields.valueY = "cci";
    series.yAxis = valueAxis;
    series.tooltipText = generalForm.chartParameters.balloons ? "cci: {valueY.value.formatNumber('" + formatString + "')}" : "";
    series.name = "CCI";
    series.hiddenInLegend = true;
    series.legendSettings.labelText = "[{color}] cci: {valueY.value.formatNumber('" + formatString + "')}";
    series.stroke = "blue";
    series.tooltip.getFillFromObject = false;
    series.tooltip.background.fill = "blue";

    createCciGuides(chart);
}

function setCciTooltips(chart, balloons, generalForm) {
    let digits = spreadDigits(generalForm.selected[0], generalForm.instrument);
    console.log("digits=", digits);
    let formatString = "#." + "0".repeat(digits);

    let series = chart.map.getKey("cci-series");
    // console.log("cciSeries=", series);
    if (typeof series !== 'undefined') {
        series.tooltipText = balloons ? "cci: {valueY.value.formatNumber('" + formatString + "')}" : "";
    }
}

export {addCciPanel, removeCciPanel, setCciTooltips};