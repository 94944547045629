import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "@/store";
//import MockService from "@/core/mock/mock.service";

Vue.config.productionTip = false;
export const bus = new Vue();

// Global 3rd party plugins
import "popper.js";
import "tooltip.js";
import PerfectScrollbar from "perfect-scrollbar";
window.PerfectScrollbar = PerfectScrollbar;
import ClipboardJS from "clipboard";
window.ClipboardJS = ClipboardJS;

// Vue 3rd party plugins
import i18n from "@/core/plugins/vue-i18n";
import vuetify from "@/core/plugins/vuetify";
import "@/core/plugins/portal-vue";
import "@/core/plugins/bootstrap-vue";
import "@/core/plugins/perfect-scrollbar";
import "@/core/plugins/highlight-js";
import "@/core/plugins/inline-svg";
import "@/core/plugins/apexcharts";
import "@/core/plugins/treeselect";
import "@/core/plugins/metronic";
import "@mdi/font/css/materialdesignicons.css";
import "@/core/plugins/formvalidation";

import {setCommoditiesArray} from "@/js/main";

import { firestorePlugin } from 'vuefire';
Vue.use(firestorePlugin);

import VueTour from 'vue-tour';
require('vue-tour/dist/vue-tour.css');
Vue.use(VueTour);

store.dispatch('siteData/bindCommoditiesArray').then(parsedData => {
    console.log("commoditiesArray=", JSON.parse(JSON.stringify(parsedData)));
    // commit('siteData/setCommoditiesObject', parsedData);
    setCommoditiesArray(store.getters["siteData/commoditiesArray"]);

    let localStorageEndpoint = localStorage.getItem("endpoint");
   // console.log("localStorageEndpoint=", localStorageEndpoint);
    if(localStorageEndpoint !== null){
                let endpoint = localStorageEndpoint;
                let temp1 = endpoint.split('//');
                let temp2 = temp1[1].split('/');
              //  console.log("temp1=", temp1);
              //  console.log("temp2=", temp2);

                let baseUrl = temp1[0] + "//" + temp2[0];
              //  console.log("baseUrl=", baseUrl);

                let contextName = temp2.length > 1 ? "/" + temp2[1] : "";
               // console.log("contextName=", contextName);

                store.commit('siteData/setBaseUrl', baseUrl);
                store.commit('siteData/setContextName', contextName);
    }
            
    startVue();
});

function startVue() {
    new Vue({
        router,
        store,
        i18n,
        vuetify,
        render: h => h(App)
    }).$mount("#app");
}
