let openSpreadsInitialState = {
    "p": [1, -1, 1, 1],
    "unitMove": [50, 50, 50, 50],
    "sampleContract": ["S2020H", "S2020K", "S2020N", "S2020U"],
    "mult": [1.0, 1.0, 1.0, 1.0],
    "y1": 5,
    "open": "2020-01-01",
    "close": "2020-02-01",
    "selected": [],
    "lineWidth": 1,
    "legs": 1,
    "generator": "OpenSpreadsGenerator",
    "intracommodity": true,
    "study": "stacked",
    "grouping": "off",
    "language": "en",
    "normalization": "off",
    "normalizationDate": "1",
    "normalizationMonth": "January",
    "eye": [1.9, 0.93, 0.31],
    "showOpenSpreads": true,
    "showOpenSpreadsThicker": true,
    "show3D": true,
    "updateState": true,
    "showTradingPeriod": false,
    chartParameters: {
        "eye": [1.9, 0.93, 0.31],
        start: 20201120,
        end: 20201220,
        "balloons": false,
        hiddenFeatures: {
            expiration: false,
            title: false,
            legends: false,
            horizontalScrollbar: true,
            verticalScrollbar: false
        }
    },
    search: {},
    user: "A4A-user0",
    password: "M1-K8xQgmR",
    "numberOfContractsApart": 0,
    program: "OpenSpreads",
    instrument: "future",
    truncate: "2"
};

export {openSpreadsInitialState};