<template>
  <div style="background-color: #c7d5e7; border: 0px solid black; width: auto">
    <!--  <div style="width: 100%;"> activeModuleIndex:
          <input style="width: 35px; border:1px solid gray; text-align: right" v-model.number="activeModuleIndex">
          </div>
         program:<span style=" margin-left: 3px; margin-right: 16px">{{program}}</span>
         activeModuleName:<span style=" margin-left: 3px; margin-right: 16px">{{activeModuleName}}</span> -->
    <table style="height: calc(100vh);">
      <tr>
        <td
          style="
            vertical-align: top;
            border-right: 1px solid gray;
            padding: 0px; overflow:hidden; 
          "
          class="sidebar"
        >
          <saved-charts
            v-bind:namespace="activeModuleName"
            @newTabWithInitialState="newTabWithInitialState"
            ref="savedCharts"
            style="padding: 0px 0 0 0; border: 0px solid gray"
          ></saved-charts>
        </td>
     
        <td
          v-bind:style="{ width: width }"
          style="
            vertical-align: top;
            border: 0px solid gray;
            background-color: whitesmoke;
          "
        >

          <b-tabs
            tabs
            id="tabs"
            v-model="activeModuleIndex"
            active-nav-item-class="font-weight-bold"
            active-tab-class="font-weight-bold"
            style="
              margin-top: 8px;
              border-bottom: 1px solid darkgray;
              font-size: 0.95rem;
              overflow-y: scroll; 
              height: calc(100% - 72px);  
              overflow-x: hidden;
            "
          >
            <template v-slot:tabs-start>
              <b-nav-item v-if="moduleArray.length > 0">
                <b v-on:click="closeAllTabs" style="border: 0px solid darkgray; margin-top: 0px">close all tabs</b>
              </b-nav-item>
            </template>

            <div id="v-step-0"></div>

            <component
              v-if="!hideInput"
              v-bind:is="inputComponent"
              v-bind:namespace="activeModuleName"
              v-bind:id="activeModuleName + 'InputDiv'"
               style="
                margin-top: 0px;
                padding: 2px 15px 3px 15px;
                border-bottom: 1px solid darkgray;
                width: 100%;
              "
            ></component>

            <b-tab
              v-for="module in moduleArray"
              v-bind:key="module.moduleName"
              v-bind:ref="module.moduleName + 'Output'"
              active
              style="border-top: 0px solid black"
            >
              <template #title>
                <span style="margin-left: -10px; border: 0px solid black">
                  <span
                    style="margin-right: 0px; font-weight: bold; color: black"
                  >
                    {{ module.tabTitle }}
                  </span>
                  <span
                    v-if="
                      typeof $store.state[module.moduleName] !== 'undefined' &&
                      activeModuleName === module.moduleName
                    "
                    style="width: auto"
                  >
                    <b-button
                      id="popover-3"
                      variant="light"
                      @click="toggleHideInput"
                      style="
                        background-color: white;
                        height: auto;
                        margin: 0px 3px 2px 1px;
                        border: 0px solid darkgray;
                        padding: 0 4px 0px 4px;
                      "
                    >
                      <i
                        v-if="!hideInput"
                        class="far fa-eye-slash"
                        style="margin: 0 0px 0 0; color: black"
                      ></i>
                      <i
                        v-if="hideInput"
                        class="far fa-thin fa-eye"
                        style="margin: 0 0px 0 0; color: black"
                      ></i>
                    </b-button>

                    <b-popover
                      target="popover-3"
                      triggers="hover"
                      placement="top"
                    >
                      <span style="font-size: 12px">{{
                        (hideInput ? "show" : "hide") + " input"
                      }}</span>
                    </b-popover>

                    <loading-indicator
                      v-if="
                        typeof $store.state[module.moduleName] !==
                          'undefined' && activeModuleName === module.moduleName
                      "
                    ></loading-indicator>

                    <b-button
                      size="sm"
                      @click="closeTab(module.moduleName)"
                      variant="dark"
                      style="
                        height: 10px;
                        width: 10px;
                        margin: 0 -12px 4px 0px;
                        padding-right: 2px;
                      "
                    >
                      <span class="h5 mb-2"
                        ><b-icon
                          icon="x-square-fill"
                          style="margin: -8px 0 19px -14px"
                        ></b-icon>
                      </span>
                    </b-button>
                  </span>
                </span>
              </template>

              <div style="background-color: white">
                <basic-chart-output 
                  v-if="module.program === 'BasicCharts'"
                  v-bind:windowHeight="windowHeight"
                  v-bind:namespace="module.moduleName"
                ></basic-chart-output>
                <calculator-output 
                  v-if="module.program === 'Calculator'" 
                  v-bind:namespace="module.moduleName"
                ></calculator-output>
                <stock-chart-output
                  v-if="module.program === 'StockCharts'"
                  v-bind:namespace="module.moduleName"
                ></stock-chart-output>
                <test-output
                  v-if="module.program === 'TestCharts'"
                  v-bind:namespace="module.moduleName"
                ></test-output>
                <forward-curves-output
                  v-if="module.program === 'ForwardCurves'"
                  v-bind:namespace="module.moduleName"
                ></forward-curves-output>
                <saved-charts-admin-output
                  v-if="module.program === 'SavedChartsAdmin'"
                  v-bind:namespace="module.moduleName"
                ></saved-charts-admin-output>
                <search-output
                  v-if="module.program === 'Search'"
                  v-bind:namespace="module.moduleName"
                  @newTabWithInitialState="newTabWithInitialState"
                ></search-output>
                <trade-maps-output
                  v-if="module.program === 'TradeMaps'"
                  v-bind:namespace="module.moduleName"
                ></trade-maps-output>
                <spread-coverage-output
                  v-if="module.program === 'SpreadCoverage'"
                  v-bind:namespace="module.moduleName"
                ></spread-coverage-output>
                <history-output
                  v-if="module.program === 'History'"
                  v-bind:namespace="module.moduleName"
                ></history-output>
                <multiple-series-charts-output
                  v-if="
                    ['LongTermCharts', 'OpenSpreads'].includes(module.program)
                  "
                  v-bind:namespace="module.moduleName"
                ></multiple-series-charts-output>
                <time-series-chart-output
                  v-if="module.program === 'TimeSeriesCharts'"
                  v-bind:namespace="module.moduleName"
                ></time-series-chart-output>
                <watch-list-output
                  v-if="module.program === 'WatchList'"
                  v-bind:namespace="module.moduleName"
                ></watch-list-output>
                <trade-explorer-output
                  v-if="module.program === 'TradeExplorer'"
                  v-bind:namespace="module.moduleName"
                ></trade-explorer-output>
                <firestore-search-output
                  v-if="module.program === 'FirestoreSearch'"
                  v-bind:namespace="module.moduleName"
                  @newTabWithInitialState="newTabWithInitialState"
                ></firestore-search-output>
                
              </div>
            </b-tab>
            <template v-slot:tabs-end>
              <b-nav-item v-b-modal.add-tab-modal>
                <b style="border: 0px solid darkgray; padding: 0px" id="v-step-2">+ add tab</b>
              </b-nav-item>

              <b-modal
                size="sm"
                id="add-tab-modal"
                ref="add-tab-modal"
                title="Add Tab"
                style="color: black"
              >
                <table border="0">
                  <tr>
                    <td>
                      <h5>Please select chart or study.</h5>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b-list-group
                        v-model="newProgram"
                        v-for="(value, i) in programOptions"
                        v-bind:key="i"
                      >
                        <b-list-group-item
                          button
                          v-on:click="newTab(programOptions[i].value)"
                          >{{ programOptions[i].text }}</b-list-group-item
                        >
                      </b-list-group>
                    </td>
                  </tr>
                </table>
              </b-modal>
            </template>
          </b-tabs>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import generalForm from "../store/generalFormModule";
//import moment from "moment";


import basicChartOutput from "@/components/programs/BasicChart/basic-chart-output";
import stockChartOutput from "@/components/programs/StockChart/stock-chart-output";
import calculatorOutput from "@/components/programs/Calculator/calculator-output";
import forwardCurvesOutput from "@/components/programs/ForwardCurves/forward-curves-output";
import searchOutput from "@/components/programs/Search/search-output";
import startPageOutput from "@/components/programs/StartPage/start-page-output";
import tradeMapsOutput from "@/components/programs/TradeMaps/trade-maps-output";
import historyOutput from "@/components/programs/History/history-output";
import spreadCoverageOutput from "@/components/programs/SpreadCoverage/spread-coverage-output";
import multipleSeriesChartsOutput from "@/components/programs/multiple-series-charts-output";
import timeSeriesChartOutput from "@/components/programs/TimeSeriesChart/time-series-chart-output";
import watchListOutput from "@/components/programs/WatchList/watch-list-output";
import tradeExplorerOutput from "@/components/programs/TradeExplorer/trade-explorer-output";
import firestoreSearchOutput from "@/components/programs/FirestoreSearch/firestore-search-output";

import { baseInitialState } from "@/components/programs/base-initial-state";
const savedCharts = () => import("@/components/saved-charts");
import {storeTab, programCompositionArray, setCommoditiesArray, changeCommodityLeg, contractNameDecomposer, getProgramComposition} from "@/js/main";
import loadingIndicator from "@/components/loading-indicator";
import * as fb from "@/firebase";
import { bus } from "@/main";
import _ from "lodash";

export default {
  components: {
    savedCharts,
    basicChartOutput,
    stockChartOutput,
    calculatorOutput,
    forwardCurvesOutput,
    searchOutput,
    startPageOutput,
    tradeMapsOutput,
    spreadCoverageOutput,
    historyOutput,
    multipleSeriesChartsOutput,
    loadingIndicator,
    timeSeriesChartOutput,
    watchListOutput,
    tradeExplorerOutput,
    firestoreSearchOutput //, usersOutput, playbackOutput, savedChartsAdminOutput
  },
  created(){
   /* this.$nextTick(() => {
      fb.db.collection("tabs").doc(this.$store.state.user.email).collection("activeModuleName").doc("activeModuleName-document")
               .get().then((doc) => {
                if (doc.exists) {
                    let activeModuleName = doc.data().activeModuleName;
                    console.log("activeModuleName=", activeModuleName);
                    let moduleArray = this.$store.getters['moduleArray'];
                    console.log("moduleArray=", JSON.parse(JSON.stringify(moduleArray)));
                    let index = moduleArray.map(x => x.moduleName).indexOf(activeModuleName);
                    console.log("index=", index);
                    this.$store.commit("setActiveModuleIndex", index);
                        //  this.activeModuleIndex = 2;

                  } else {
                    // doc.data() will be undefined in this case
                    console.log("No such document!");
                }
              }).catch((error) => {
                console.log("Error getting document:", error);
              });

    }); */
  },
  mounted() {
   // console.log("mounted() started.");
    window.addEventListener("resize", this.windowResizeEventHandler);

    bus.$on("changeTab", (program) => {
      console.log("from bus, changeTab: program=", program);
      // console.trace();
      this.changeTab(program);
    });

    bus.$on("newTab", (program) => {
      console.log("from bus, newTab: program=", program);
      // console.trace();
      this.newTab(program);
    });
  },
  destroyed() {
    console.log("tabs-box.vue destroyed() starting.");
    this.$store.commit("setActiveModuleIndex", null);
    window.removeEventListener("resize", this.windowResizeEventHandler);

    bus.$off();
  },
  data: function () {
    return {
      windowHeight: window.innerHeight,
      lastCheckTime: 0,
      newProgram: null,
      playbackControlKey: 0
    };
  },
  computed: {
    width() {
      return "100%";  
      /*["TradeMaps", "Calculator"].includes(this.program)  
        ? "fit-content" 
        :  "100%";*/
    },
    programOptions() {
      return programCompositionArray(this.role);
    },
    role(){
      return this.$store.state.user.role;
    },
    email(){
      return this.$store.state.user.email;
    },
    showStateEditor() {
      return this.$store.state.showStateEditor;
    },
    hideInput: {
      get() {
        let hideInput;
        if (typeof this.activeModule !== "undefined" && this.activeModule !== null) {
          hideInput = this.activeModule.hideInput;
        } else {
          hideInput = false;
        }
        //  console.log("hideInput=", hideInput);
        return hideInput;
      },
      set(hideInput) {
        this.$store.commit(this.activeModuleName + "/setHideInput", hideInput);
      },
    },
    showPlaybackControl() {
      if (
        typeof this.activeModule !== "undefined" &&
        this.activeModule !== null
      ) {
        return this.activeModule.showPlaybackControl;
      } else {
        return false;
      }
    },
    activeModuleIndex: {
      get() {
       // console.log("activeModuleIndex() get starting. this.$store.state.activeModuleIndex=", this.$store.state.activeModuleIndex);
        return this.$store.state.activeModuleIndex;
      },
      set(activeModuleIndex) {
        console.log("set activeModuleIndex()  starting. activeModuleIndex=", activeModuleIndex);
       // console.trace();
        //  console.log("arguments.callee.caller.name=", arguments.callee.caller.name); Can't use in srict mode.
          this.$store.commit("setActiveModuleIndex", activeModuleIndex);
        /*  let currentTime = (new Date).getTime();   This block causes ugly problems!!!!!
                     // console.log("currentTime=", currentTime, " this.lastCheckTime=", this.lastCheckTime);
                     let timeIncrement = currentTime - this.lastCheckTime;
                     // console.log("timeIncrement=", timeIncrement);

                     if (timeIncrement > 200) {
                     this.$store.commit('setActiveModuleIndex', activeModuleIndex);
                     }
                     this.lastCheckTime = (new Date).getTime();*/
      },
    },
    activeModuleName() {
      let activeModuleName = this.$store.getters["activeModuleName"];
      // console.log("activeModuleName=", activeModuleName);
      return activeModuleName;
    },
    activeModule: {
      get() {
        let activeModule = this.$store.state[this.activeModuleName];
        // console.log("activeModule=", activeModule);
        return typeof activeModule !== "undefined" ? activeModule : null;
      },
      set() {},
    },
    activeModuleJson() {
     // console.log("activeModuleJson() starting.");
      let generalForm = JSON.parse(JSON.stringify(this.activeModule));
      //  delete generalForm.playback;
      //  delete generalForm.browserSideOnly;

      return JSON.stringify(generalForm);
    },
    moduleArray: {
      get() {
        let moduleArray = this.$store.getters["moduleArray"];
        // console.log("moduleArray=", moduleArray);
        return moduleArray;
      },
      set() {
        // console.log("program set() starting.");
        // console.log("moduleArray=", moduleArray);
      },
    },
    inputComponent: {
      get() {
       // console.log("inputComponent get() starting. this.program=", this.program);
        if (typeof this.moduleArray[this.activeModuleIndex] !== "undefined") {
          let role = this.$store.state.user.role;
         // console.log("getProgramComposition(this.program, role)=", getProgramComposition(this.program, role));
          let returnComponent = getProgramComposition(this.program, role).input;
         // console.log("returnComponent=", returnComponent);
          return returnComponent;
        } else {
          return null;
        }
      },
    },
    program: {
      get() {
       // console.log("program get() starting. this.moduleArray[this.activeModuleIndex]=", this.moduleArray[this.activeModuleIndex]);
        if (typeof this.moduleArray[this.activeModuleIndex] !== "undefined") {
          return this.moduleArray[this.activeModuleIndex].program;
        } else {
          return null;
        }
      },
      set() {
        // console.log("program set() starting.");
        // console.log("program=", program);
      },
    },
    inputDivHeight() {
      // console.log("inputDivHeight() starting. this.activeModule=", this.activeModule);
      if (typeof this.activeModule !== "undefined" && this.activeModule !== null) {
        let inputDivHeight = this.activeModule.browserSideOnly.inputDivHeight;
        //  console.log("inputDivHeight=", inputDivHeight);
        return inputDivHeight;
      } else {
        return null;
      }
    },
  },
  watch: {
   /* activeModuleIndex: function () {
      // console.log("watch activeModuleIndex=", activeModuleIndex);
    let refName = this.$store.getters["activeModuleName"] + "Output";
      // console.log("activeModuleName=", activeModuleName); 
    console.log("refName=", refName);
    console.log("this.$refs=", this.$refs);
    console.log("this.$refs[refName]=", this.$refs[refName]);
    console.log("this.$refs[refName].length=", this.$refs[refName].length);
    console.log("this.$refs[refName][0]=", this.$refs[refName][0]);
    
    this.$refs[refName][0].activate();
    },*/
    "$store.state.siteData.commoditiesArray"(newCommoditiesArray/*, oldCommoditiesArray*/) {
     // console.log("watch newCommoditiesArray=", newCommoditiesArray, " oldCommoditiesArray=", oldCommoditiesArray);
     // console.log("this.activeModuleName=", this.activeModuleName);
      setCommoditiesArray(newCommoditiesArray);
      this.$store.dispatch(this.activeModuleName + "/getContractListsFromServer");
    },
    windowHeight: function (newWindowHeight, oldWindowHeight) {
      // console.log("watch newWindowHeight=", newWindowHeight, " oldWindowHeight=", oldWindowHeight);
      if (newWindowHeight !== oldWindowHeight) {
        // console.log("watch windowHeight=", newWindowHeight);
        this.setChartHeight();
      }
    },
    inputDivHeight: function (/*inputDivHeight*/) {
      // console.log("watch inputDivHeight=", inputDivHeight + " this.windowHeight=", this.windowHeight);
      this.setChartHeight();
    },
    showPlaybackControl: function (/*showPlaybackControl*/) {
      // console.log("watch showPlaybackControl=", showPlaybackControl);
      this.setChartHeight();
    },
    /* activeModule: function (activeModule) {
             console.log("watch activeModule = ", activeModule);
             // this.setChartHeight();
             },*/
    activeModuleJson: function (newActiveModuleJson, oldActiveModuleJson) {
     // console.log("watch activeModuleJson");
      if (newActiveModuleJson !== oldActiveModuleJson) {
        // const jsondiffpatch = require('jsondiffpatch').create();
        // const diff = jsondiffpatch.diff(newActiveModuleJson, oldActiveModuleJson);
        // console.log("diff=", diff);
        // console.log("diff=", JSON.parse(diff));

        //  console.log("watching activeModuleJson");
        storeTab(JSON.parse(newActiveModuleJson), this.email);
        this.updateInitializingState();
      }  
  },
  },
  methods: {
  //  onInput(/*value*/) {
  /*  console.log("onInput() starting.");
        if(!this.visitedTabs.includes(this.activeModuleName)){
          let arr = this.visitedTabs.slice();
          arr.push(this.activeModuleName);
          this.visitedTabs = arr.sort();
        }
      }, */
    updateInitializingState(){
    // console.log("updateInitializingState() starting.");
      if (!["Search", "StockCharts"].includes(this.program)) {
        let generalForm = JSON.parse(JSON.stringify(this.$store.state[this.activeModuleName]));
        // console.log("generalForm=", JSON.parse(JSON.stringify(generalForm)));

        if (["OpenSpreads"].includes(this.program)) {
         // generalForm.selected = [generalForm.sampleContract[0]];
         console.log("generalForm.selected[0]=", generalForm.selected[0]);
         generalForm.selected = [generalForm.selected[0]];
         let contract = generalForm.selected[0].split('/');
         generalForm.legs = contract.length;
         contract.forEach((x, i) => {
            generalForm.sampleContract[i] = x;
         })
         console.log("generalForm=", JSON.parse(JSON.stringify(generalForm)));
        }
        this.$store.commit("user/setInitializingState", generalForm);
      } 
    },
    windowResizeEventHandler(e) {
      //  console.log("window height=", e.target.innerHeight);
      this.windowHeight = e.target.innerHeight;
    },
    setChartHeight: _.debounce(function () {
      // console.log("this.activeModule.showPlaybackControl");
      /*  console.log("setChartHeight() starting. this.inputDivHeight=", this.inputDivHeight,
                 " this.windowHeight=", this.windowHeight + " this.activeModuleName=", this.activeModuleName); */
      //console.trace();
      if (
        this.program !== "TradeMaps" &&
        this.activeModuleName !== null &&
        this.inputDivHeight !== null
      ) {
        let elem;
        if (this.program === "History") {
          elem = document.getElementById(this.activeModuleName + "-chartTable");
        } else {
          elem = document.getElementById(this.activeModuleName + "-chartDiv");
        }

        const elem2 = document.getElementById(
          this.activeModuleName + "-plotlyRibbonChartDiv"
        );
        const elem3 = document.getElementById(
          this.activeModuleName + "-plotlyRibbonChartInstructions"
        );
        let playbackControlHeight = this.showPlaybackControl ? 33 : 0;
        let pagePadding = 2 * 10; // See $page-padding in _variables.scss
        let newHeight =
          this.windowHeight -
          this.inputDivHeight -
          playbackControlHeight -
          pagePadding -
          95;
          console.log('newHeight:', newHeight);

          if (this.program === "History") {
             document.getElementsByClassName("resize-multiple-line-chart").forEach((element) => {
                element.style.height = (newHeight/parseInt(this.$store.state[this.activeModuleName].chartParameters.rowsPerPage)) + "px";
             });                    
           } else {

        // console.log("elem=", elem);

        if (elem !== null) {
          elem.style.height = newHeight + "px";
         //  console.log("elem=", elem);
        }
        if (elem2 !== null) {
          let instructionsHeight = elem2 !== null ? elem3.offsetHeight : 0;
          elem2.style.height = newHeight - instructionsHeight + "px";
        }
      }
      }
    }, 200),
    toggleHideInput() {
      console.log("toggleHideInput() starting.");
      console.log("this.hideInput=", this.hideInput);
      this.$store.commit(
        this.activeModuleName + "/setHideInput",
        !this.hideInput
      );
    },
    transformLegacyState(state) {
      console.log("transformLegacyState() starting.");
      if (state.program === "StackedSpreads") {
        state.program = "LongTermCharts";
        state.aligned = true;
      }
      console.log("state=", state);
      return state;
    },
    newTabWithInitialState(state) {
      console.log("newTabWithInitialState() starting. state=", JSON.parse(JSON.stringify(state)));

     if(state === "No such document!"){
        alert("Error! No such document!");
     } else if (this.checkForHistoryTab(state.program)) {
        alert("Sorry. Only one History tab is allowed.");
      } else {
        let initialState = this.transformLegacyState(state); //_.merge(baseInitialState, state);

        let nextModuleName = this.$store.getters["nextModuleName"];
        console.log("nextModuleName=", nextModuleName);
        if (!this.$store.hasModule(nextModuleName)) {
          this.$store.registerModule(nextModuleName, generalForm);
          this.$store.commit(nextModuleName + "/setGeneralForm", initialState);
          this.$store.commit(nextModuleName + "/setModuleName", nextModuleName);
          this.$store.commit(
            nextModuleName + "/setConstrainSampleContract",
            false
          );
          this.$store.commit("resetNumberOfAjaxCalls");
          this.$store.dispatch(nextModuleName + "/getContractListsFromServer");
          setTimeout(() => {
            // console.log("this.$refs=", this.$refs);
            let refName = nextModuleName + "Output";
            //  console.log("refName=", refName);
            // console.log("this.$refs[refName]=", this.$refs[refName]);

            this.$refs[refName][this.$refs[refName].length - 1].activate();
          }, 100);
        }
      }
    },
    checkForHistoryTab(program) {
      let programs = this.moduleArray.map((x) => x.program);
     // console.log("programs=", programs);
      return program === "History" && programs.includes(program);
    },
    newTab(program) {
      //console.log("newTab() starting.");

      if (this.checkForHistoryTab(program)) {
        alert("Sorry. Only one History tab is allowed.");
      } else {
        let initialState = this.createInitialState(program);
        console.log("initialState=", JSON.parse(JSON.stringify(initialState)));
        this.$refs["add-tab-modal"].hide();
        this.newProgram = null;
        let nextModuleName = this.$store.getters["nextModuleName"];
        console.log("nextModuleName=", nextModuleName);
        if (!this.$store.hasModule(nextModuleName)) {
          // console.log("generalForm=", generalForm);
          this.$store.registerModule(nextModuleName, generalForm);
          this.$store.commit(nextModuleName + "/setGeneralForm", initialState);
          this.$store.commit(nextModuleName + "/setModuleName", nextModuleName);
          this.$store.commit(nextModuleName + "/setConstrainSampleContract", false);
          this.$store.commit("resetNumberOfAjaxCalls");
          // console.log("JSON.parse(JSON.stringify(this.$store.state[nextModuleName]))=", this.$store.state[nextModuleName]);
          this.$store.dispatch(nextModuleName + "/getContractListsFromServer");
          setTimeout(() => {
            // console.log("this.$refs=", this.$refs);
            let refName = nextModuleName + "Output";
               console.log("refName=", refName);
               console.log("this.$refs[refName]=", this.$refs[refName]);
               console.log("this.$refs[refName].length=", this.$refs[refName].length);
               console.log("this.$refs[refName][this.$refs[refName].length - 1]=", this.$refs[refName][this.$refs[refName].length - 1]);

            this.$refs[refName][this.$refs[refName].length - 1].activate();
          }, 100);
        }
      }
    },
    createInitialState(program) {
      console.log("createInitialState() starting. program=", program);
      let initialState = _.merge(JSON.parse(JSON.stringify(baseInitialState)), getProgramComposition(program, this.role).initialState);

      // console.log("this.$store.state.user=", JSON.parse(JSON.stringify(this.$store.state.user)));
      // console.log("this.activeModuleName=", this.activeModuleName);
      let initializingState = JSON.parse(JSON.stringify(this.$store.state.user.initializingState));
      console.log("initializingState=", initializingState);
      if (initializingState !== null && this.activeModuleName !== null && Object.keys(initializingState).length > 0) {
        initialState.sampleContract = initializingState.sampleContract;
        if (["TradeMaps", "OpenSpreads"].includes(program)) {
          initialState.intracommodity = true;
          let commodityFirstLeg = contractNameDecomposer(initializingState.sampleContract[0]).commoditySymbol;
          let commoditySecondLeg = contractNameDecomposer(initializingState.sampleContract[1]).commoditySymbol;
          console.log("commodityFirstLeg=", commodityFirstLeg+" commoditySecondLeg=", commoditySecondLeg);
          let index = null;
          if(commodityFirstLeg !== commoditySecondLeg){
            initialState = changeCommodityLeg(commodityFirstLeg, index, JSON.parse(JSON.stringify(initialState)));
          }
          if (program === "OpenSpreads") { // Don't remove this block!
            initialState.selected = initializingState.selected.map(spread => {
              let contracts = spread.replace(/\s/g, '').split('/');
              // console.log("contracts=", contracts);
              return contracts.pop();
            })
            console.log("initialState.selected=", initialState.selected);
          }
        } else {
          initialState.selected = initializingState.selected;
        }

        initialState.unitMove = initializingState.unitMove;
        console.log("initialState.unitMove=", initialState.unitMove);

        initialState.mult = ["TradeMaps", "StockCharts", "OpenSpreads" ].includes(program) ? [1, 1, 1, 1] : initializingState.mult;
        initialState.legs = ["TradeMaps", "StockCharts"].includes(program) && initializingState.legs > 2 ? 2 : initializingState.legs;
        initialState.p = ["StockCharts", "OpenSpreads"].includes(program) ? [1, -1, 1, 1] : initializingState.p;
        if(program === "TradeMaps"){
          initialState.p = initializingState.p[0] > 0 ? [1, -1, 1, 1] : [-1, 1, 1, 1];
        }

        initialState.open = initializingState.open;
        initialState.close = initializingState.close;
        initialState.buySell = initializingState.buySell;
        if(program != "TradeMaps"){ //This block handles Search actions.
          initialState.addRSIPanel = initializingState.addRSIPanel;
          initialState.rsiPeriod = initializingState.rsiPeriod;
        }

        if (["TradeMaps", "Calculator"].includes(program)) {
          initialState.showTradingPeriod = true;
        } else {
          initialState.showTradingPeriod = initializingState.showTradingPeriod;
        }
      }

      initialState.hideInput = false;
      console.log("initialState=", initialState);
      return initialState;
    },
    changeTab(program) {
      console.log("changeTab() starting. program=", program, " this.program=", this.program);

      if (this.checkForHistoryTab(program)) {
        alert("Sorry. Only one History tab is allowed.");
      } else {
        if (this.activeModuleName === null) {
          this.newTab(program);
        } else {
          let initialState = this.createInitialState(program);
          this.$store.commit(this.activeModuleName + "/setGeneralForm", initialState);
          this.$store.commit(this.activeModuleName + "/setConstrainSampleContract", false);
          this.$store.commit("resetNumberOfAjaxCalls");
          console.log("this.$store.state[this.activeModuleName]=", JSON.parse(JSON.stringify(this.$store.state[this.activeModuleName])));
          this.$store.dispatch(this.activeModuleName + "/getContractListsFromServer");
        }
      }
    },
    closeTab(moduleName) {
      console.log("closeTab() starting. moduleName=", moduleName);
      //   this.activeModuleIndex--;
      //  console.log("this.activeModuleIndex=", this.activeModuleIndex);

      this.$store.commit("resetNumberOfAjaxCalls");
      this.$store.unregisterModule(moduleName);
      /*   if (this.moduleArray.length === 0) {
                 this.$refs['add-tab-modal'].show();
                 }*/

      let numberOfTabs = this.$store.getters["moduleArray"].length;
      console.log("numberOfTabs=", numberOfTabs);
      if (numberOfTabs === 0) {
        this.$store.commit("user/setInitializingState", {});
      }

      fb.db
        .collection("tabs")
        .doc(this.$store.state.user.email)
        .collection("tabs")
        .doc(moduleName)
        .delete()
        .then(() => {
          console.log("tab deleted.");
        })
        .catch((error) => {
          console.error("Error removing document: ", error);
        });
    },
    closeAllTabs() {
      console.log("closeAllTabs() starting.");
      let arr = this.$store.getters["moduleArray"];
      console.log("arr=", arr);
      arr.forEach((x) => this.closeTab(x.moduleName));
      this.$store.commit("user/setInitializingState", {});
    },
  },
};
</script>