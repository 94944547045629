import Vue from "vue";
import Vuex from "vuex";
import store from "@/store";
import * as fb from "@/firebase.js";
import router from "@/router.js";

Vue.use(Vuex);

export default {
  namespaced: true,
  state: {
    id: "",
    name: "",
    username: "",
    email: "",
    role: "",
    subscriptions: [],
    authorized: process.env.VUE_APP_AUTHENTICATED || false, // false is default value
    // loading: false,

    showGetStarted: true,
    selectedSidebarComponent: null,
    showTour: true,
    symbols: "new",
    initializingState: null,
  },
  mutations: {
    setUser: function(state, newState) {
      console.log("setUser() starting.");
      Object.assign(state, newState);
    },
    setName: function(state, name) {
      state.name = name;
    },
    setEmail: function(state, email) {
      // console.log("setEmail() starting.");
      state.email = email;
    },
    setRole: function(state, role) {
      state.role = role;
    },
    setSubscriptions: function(state, subscriptions) {
      state.subscriptions = subscriptions;
    },
    setShowGetStarted: function(state, payload) {
      state.showGetStarted = payload;
    },
    setShowTour: function(state, payload) {
      state.showTour = payload;
    },
    setInitializingState(state, payload) {
     // console.trace();
      state.initializingState = payload;
    },
    setSymbols(state, payload) {
      console.log("setSymbols() starting. payload=", payload);
      state.symbols = payload;
    },
  },
  actions: {
    async logout({ commit }) {
      console.log("logout() starting.");
      commit("setTabsInitialized", false, { root: true });
      commit("setRole", "");

      await fb.auth.signOut();
      router.currentRoute.path !== "/auth" && router.push("/auth");
    },
    async fetchUserProfile({ commit }, user) {
      //  console.log("async fetchUserProfile() starting. user=", user);
      // commit('setLoading', true);
      // console.log("store.state.user=", {...store.state.user});

      return new Promise((resolve) => {
        if (store.state.user.role !== "") {
          // console.log("Getting userInfo from the store.");
          let userInfo = {
            role: store.state.user.role,
            email: store.state.user.email,
            name: store.state.user.name,
            subscriptions: store.state.user.subscriptions,
          };
          //  console.log("userInfo=", userInfo);
          resolve(userInfo);
        } else {
          console.log("Checking firebase.");
          fb.usersCollection.doc(user.email).onSnapshot((firebaseData) => {
            console.log("user.email=", user.email, " store.state.user.email=", store.state.user.email);
            const userInfo = firebaseData.data();
            console.log("userInfo=", userInfo);

            if (userInfo && [user.email, ""].includes(store.state.user.email)) {
              commit("setUser", userInfo);
            }
            resolve(userInfo);
          });
        }
      });
    },
  },
  getters: {
    user: (state) => state,
  },
};
