import {/*spreadDigits, */removeNaNs} from '../main';
import {ema} from './am4charts.main';
import * as am4charts from "@amcharts/amcharts4/charts";

function removeEma(chart) {
   // console.log("removeEma() starting.");
    let index = chart.series.indexOf(chart.map.getKey("indicator_ema_series"));
   // console.log("index=", index);
    if (index !== -1) {
        chart.series.removeIndex(index).dispose();
    }

    let data = chart.map.getKey("main-series").data;
   // console.log("data=", data);

    if(typeof data !== 'undefined'){
    data.forEach(x => {
        let keys = Object.keys(x);
        //console.log("keys=", keys);

        let filteredKeys = keys.filter(x => x.includes("indicator_ema"));
       // console.log("filteredKeys=", filteredKeys);
        filteredKeys.forEach(key => {
            delete x[key];
        });
    });
}
}

function addEma(chart, generalForm) {
   // console.log("addEma() starting.");
    removeEma(chart);

   // let selected = generalForm.selected[0];
   // let digits = spreadDigits(selected, generalForm.instrument);
   // let formatString = "#." + "0".repeat(digits);

    let period = generalForm.emaLength;
    let mainData = chart.map.getKey("main-series").data;
    //console.log("removeNaNs(mainData)=", removeNaNs(mainData));

    let mainDataPlusIndicators = ema(removeNaNs(mainData), period, "close");
   // console.log("mainDataPlusIndicators=", mainDataPlusIndicators);

    mainDataPlusIndicators.forEach(x => {
        x["indicator_ema"] = x["ema_" + period];
        delete x["ema_" + period];
    });
   // console.log("mainDataPlusIndicators=", mainDataPlusIndicators);

    let emaSeries = chart.series.push(new am4charts.LineSeries());
    emaSeries.id = "indicator_ema_series";
    emaSeries.data = mainDataPlusIndicators;
    emaSeries.dataFields.valueY = "indicator_ema";
    emaSeries.dataFields.openValueY = "indicator_ema";
    emaSeries.dataFields.dateX = "date";
    emaSeries.name = "EMA_"+ period;
    emaSeries.strokeWidth = 1;
    emaSeries.stroke = "red";
    emaSeries.fillOpacity = 0.0;
    emaSeries.stacked = false;
   // emaSeries.legendSettings.labelText = "[{color}]ema_20: {valueY.formatNumber('" + formatString + "')}";
    emaSeries.hiddenInLegend = true;
    // emaSeries.yAxis = chart.map.getKey("main-axis");
}

export {addEma, removeEma};