<template>
    <div>

        <div v-bind:id="namespace+'-error'"></div> 
        <basic-chart v-bind:ref="namespace"
                     v-bind:namespace="namespace"  
                     v-bind:div-name="namespace+'-chartDiv'"
                     v-bind:parsedData="parsedData"></basic-chart>  
    </div>
</template>

<script>

    import basicChart from '@/components/am4charts-basic-chart';
    // import {addSingleSeasonal} from "@/js/am4charts-plugins/am4charts.single-seasonal";
    import $ from "jquery";
    import moment from 'moment';
    import {isSpreadOpen, eodContractToYahooContract, areSameUnitMoves, areSameUnits} from "@/js/main";
    // import {spreads} from "@/js/am4charts-plugins/am4charts.spread-bars.js";
    import axios from "axios";

    export default {
        components: {
            basicChart
        },
        mounted() {
            console.log("basic-chart-output.vue mounted() starting.");
            this.$store.commit(this.namespace + '/setTabTitle', this.displayTicker);
            if (this.activeModuleName === this.namespace && !this.initialized) {
                this.getData();
            }
        },
        props: ['namespace'],
        data: function () {
            return{
                parsedData: null,
                initialized: false
            }
        },
        computed: {
            ticker() {
                return this.$store.getters[this.namespace + "/ticker"];
            },
            displayTicker() {
                return this.$store.getters[this.namespace + "/displayTicker"];
            },
            activeModuleName() {
                return  this.$store.getters['activeModuleName'];
            },
            showPlaybackControl() {
                return this.$store.state[this.namespace].showPlaybackControl;
            },
            truncate() {
                return this.$store.state[this.namespace].truncate;
            },
            symbols() {
                return this.$store.state.user.symbols;
            }
        },
        watch: {
            ticker: function (newTicker, oldTicker) {
                console.log("watch newTicker=", newTicker, " oldTicker=", oldTicker, " namespace=", this.namespace);

                let generalForm = JSON.parse(JSON.stringify(this.$store.state[this.namespace]));
                if (newTicker !== oldTicker && newTicker !== null) {
                    this.getData();
                    if (generalForm.program === "BasicCharts") {
                        this.$store.commit(this.namespace + '/setTabTitle', this.displayTicker);
                    }
                } else {
                    this.parsedData = null;
                }
            },
            symbols(symbols) {
                console.log("watch: symbols=", symbols);
                console.log("this.displayTicker=", this.displayTicker);
                let generalForm = JSON.parse(JSON.stringify(this.$store.state[this.namespace]));
                if (generalForm.program === "BasicCharts") {
                    this.$store.commit(this.namespace + '/setTabTitle', this.displayTicker);
                }
            },
            truncate() {
                console.log("watching truncate");
                this.getData();
            },
            activeModuleName: function (activeModuleName) {
                console.log("activeModuleName=", activeModuleName, " namespace=", this.namespace,
                        " this.initialized=", this.initialized, " this.$store.state[this.namespace].selected[0]=", this.$store.state[this.namespace].selected[0],
                        " this.ticker=", this.$store.getters[this.namespace + "/ticker"]);

                if (activeModuleName === this.namespace && !this.initialized
                        && typeof this.$store.state[this.namespace].selected[0] !== 'undefined') {
                    // console.log("CCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCC");
                    this.getData();
                }
            }
        },
        methods: {
            normalizeForUnitMoveDifferencesBetweenBarchartAndEod(data, commodity) {
                console.log("normalizeForUnitMoveDifferencesBetweenBarchartAndEod() starting. commodity=", commodity);

                let normalizationRatios = {
                    HG: 100,
                    SI: 100
                };

                let normalizedData;
                if (Object.keys(normalizationRatios).includes(commodity)) {
                    //  console.log("Normalizating data. data=", data);
                    normalizedData = data.map(x => {
                        //  console.log("x=", x);
                        ["open", "high", "low", "price"].forEach(y => {
                            x[y] = normalizationRatios[commodity] * x[y];
                        });
                        return x;
                    });
                } else {
                    normalizedData = data;
                }
                return normalizedData;
            },
            getLastData(parsedData) {
                console.log("getLastData() starting.");

                return new Promise((resolve) => {

                    let generalForm = JSON.parse(JSON.stringify(this.$store.state[this.namespace]));
                    if (isSpreadOpen(generalForm.selected[0])) {
                        let  contracts = generalForm.selected[0].split("/");
                        console.log("contracts=", contracts);

                        let rapidapiSampleContracts = contracts.map(contract => eodContractToYahooContract(contract.trim()).twoDigitYear);
                        console.log("rapidapiSampleContracts=", rapidapiSampleContracts);
                        if (rapidapiSampleContracts.some(x => typeof x === "undefined")) {
                            console.log("No data for some contracts.");
                            resolve("not_defined");
                        } else {

                            let listString = rapidapiSampleContracts.slice(0, generalForm.legs).join(',');
                            console.log("listString=", listString);

                            const options = {
                                method: 'GET',
                               // url: 'https://apidojo-yahoo-finance-v1.p.rapidapi.com/market/v2/get-quotes',
                                url: 'https://app.charts.alpha4all.it/quote_b.php?symbol='+listString,
                               /* params: {region: 'US', symbols: listString},
                                headers: {
                                    'X-RapidAPI-Key': '46225730fdmsha874d743956a72dp155f24jsn54917cf1d77e',
                                    'X-RapidAPI-Host': 'apidojo-yahoo-finance-v1.p.rapidapi.com'
                                }*/
                            };

                            let that = this;
                            axios.request(options).then(function (response) {
                                console.log("response.data.quoteResponse.result=", response.data.quoteResponse.result);
                                if (response.data.quoteResponse.result.length === 0) {
                                    resolve("no_data");
                                } else {
                                    let priceArray = response.data.quoteResponse.result.map(x => {
                                        console.log("x=", x);
                                        return {
                                            symbol: x.underlyingExchangeSymbol,
                                            date: moment.unix(x.regularMarketTime).format("YYYYMMDD"),
                                            open: x.regularMarketOpen,
                                            high: x.regularMarketDayHigh,
                                            low: x.regularMarketDayLow,
                                            price: x.bid === 0 || x.ask === 0 ? x.regularMarketPreviousClose :   (x.bid + x.ask ) / 2 ,
                                            volume: x.regularMarketVolume,
                                            openInterest: x.openInterest
                                        };
                                    });
                                    console.log("priceArray=", priceArray);

                                    let dateArray = priceArray.map(x => x.date);
                                    console.log("dateArray=", dateArray);

                                    let sameDates = dateArray.every(x => x === dateArray[0]);
                                    console.log("sameDates=", sameDates);

                                    if(!sameDates){
                                      resolve("no_current_data");
                                    }

                                    let commodity = that.$store.getters[that.namespace + '/c'][0];
                                    let normalizedPriceArray = that.normalizeForUnitMoveDifferencesBetweenBarchartAndEod(JSON.parse(JSON.stringify(priceArray)), commodity);
                                    console.log("normalizedPriceArray=", normalizedPriceArray);

                                    let close;
                                    let closeArray = [];
                                    //let date = normalizedPriceArray[0].date;
                                    contracts.forEach((contract, index) => {
                                        console.log("contract=", contract);
                                        close = normalizedPriceArray.find(x => x.symbol === eodContractToYahooContract(contract.trim()).twoDigitYear);
                                        console.log("close=", close);

                                        let totalMult;
                                        let leg = index;
                                        if (areSameUnitMoves(contracts.join('/')) && areSameUnits(contracts.join('/'))) {
                                            totalMult = generalForm.mult[leg] * generalForm.p[leg];
                                        } else {
                                            totalMult = generalForm.mult[leg] * generalForm.p[leg] * generalForm.unitMove[leg];
                                        }
                                        // console.log("totalMult=", totalMult);

                                        let closeObject = {contract: contract, close: totalMult * close.price};
                                        closeArray.push(closeObject);
                                    });
                                    console.log("closeArray=", closeArray);

                                    let spreadClose = closeArray.map(x => x.close).reduce((a, b) => a + b, 0)
                                    console.log("spreadClose=", spreadClose);

                                    let values = Object.values(parsedData.values);
                                    let lastDay = values[values.length - 1];
                                    console.log("lastDay=", JSON.parse(JSON.stringify(lastDay)));
                                    let lastDate = moment(lastDay.date).format("YYYYMMDD");
                                    console.log("lastDate=", lastDate);

                                    let newBar = {date: moment(close.date).toDate(), close: spreadClose};
                                    if (generalForm.legs === 1) {
                                        newBar["open"] = normalizedPriceArray[0].open;
                                        newBar["high"] = normalizedPriceArray[0].high;
                                        newBar["low"] = normalizedPriceArray[0].low;
                                        newBar["volume"] = normalizedPriceArray[0].volume;
                                        newBar["openInterest"] = normalizedPriceArray[0].openInterest;
                                    }
                                    /* parsedData.values[close.date]= newBar;
                                     that.parsedData = parsedData;
                                     console.log("that.parsedData=", JSON.parse(JSON.stringify(that.parsedData))); */
                                    //console.log("this.parsedData[this.parsedData.length-1]=", that.parsedData[that.parsedData.length-1]);

                                    // that.addData(newBar);

                                    resolve(newBar);
                                }
                            })

                                    /*  newBar["open"] = open;
                                     newBar["high"] = high;
                                     newBar["low"] = low;
                                     newBar["close"] = close; */


                                    /*         let commodity = that.$store.getters[that.namespace + '/c'][0];
                                     // console.log("commodity=", commodity);
                                     let normalizedTemp = this.normalizeForUnitMoveDifferencesBetweenBarchartAndEod(JSON.parse(JSON.stringify(temp)), commodity);
                                     // console.log("normalizedTemp=", normalizedTemp);
                                     
                                     parsedData = {};
                                     parsedData.values = normalizedTemp.reduce((obj, item) => {
                                     obj[item["date"].replace(/-/g, "")] = item;
                                     return obj;
                                     }, {});*/

                                    /*  parsedData.title = spreadTitle(generalForm); // + "  (" + symbol + ")";
                                     parsedData.units = spreadUnits(generalForm.selected[0]);
                                     */

                                    .catch(function (error) {
                                        console.error("error=", error);
                                        resolve("no_data");
                                    });
                        }
                    } else {
                        resolve("not_open");
                    }
                });

            },
            addData(bar) {
                // console.log("addData() starting.");
                this.$refs[this.namespace].addData(bar);


            },
            getData: function () {
                console.log("getData() starting.");
                // console.trace();

                this.initialized = true;
                // console.log("this.$refs=", this.$refs);
                this.$refs[this.namespace].removeChart();

                if (this.ticker !== null) {
                    this.$store.commit('incrementNumberOfAjaxCalls');
                    let generalForm = JSON.parse(JSON.stringify(this.$store.state[this.namespace]));

                    // console.log("generalForm.selected[0]=", generalForm.selected[0]);

                    // let isOpen = isSpreadOpen(generalForm.selected[0]);
                    // console.log("isOpen=", isOpen);

                    /*  if (isOpen) {
                     this.$store.commit(this.namespace + '/setGeneralForm', generalFormToBarchartTicker(generalForm));
                     } else {
                     this.$store.commit(this.namespace + '/setBarchartTicker', "");
                     } */
                    generalForm = JSON.parse(JSON.stringify(this.$store.state[this.namespace]));
                    // console.log("barchartTicker=", generalForm.barchartTicker);

                    generalForm = JSON.parse(JSON.stringify(this.$store.state[this.namespace]));
                    delete generalForm.browserSideOnly;
                    delete generalForm.chartParameters;
                    delete generalForm.playback;
                    generalForm.generator = "BasicBarsGenerator";
                    generalForm.study = "basic";
                    //  console.log("generalForm=", generalForm);

                    let json = JSON.stringify(generalForm, null, 2);
                    if (generalForm.selected.length > 0) {
                        let that = this;
                        var currentRequest = $.ajax({
                            url: this.$store.state.siteData.baseUrl + this.$store.state.siteData.contextName + "/BasicEodBarsControllerServlet/",
                            type: "POST",
                            data: {"json": json},
                            beforeSend: function () {
                                //  console.log("beforeSend starting.");
                                if (currentRequest != null) {
                                    //  console.log("aborting previous request.");
                                    currentRequest.abort();
                                }
                            },
                            success: function (data) {
                                // console.log("data=", data);
                                that.$store.commit('decrementNumberOfAjaxCalls');
                                let parsedData = JSON.parse(data)[0];
                                let dataSource = JSON.parse(data)[1].dataSource;
                                console.log("dataSource=", dataSource, " parsedData=", JSON.parse(JSON.stringify(parsedData)));

                                that.$store.commit(that.namespace + '/setDataSource', dataSource);

                                if (parsedData.values === "none") {
                                    console.log('parsedData.values === "none"');
                                } else {
                                    //     $('#' + that.namespace + "-error").hide();

                                    console.log("data source: eod");

                                }

                              /*  that.getLastData(parsedData).then(newBar => {
                                    console.log("newBar=", newBar);
                                    if (!["not_defined", "no_data", "not_open", "no_current_data"].includes(newBar)) {
                                        console.log("newBar=", JSON.parse(JSON.stringify(newBar)));
                                        let values = Object.values(parsedData.values);
                                        let lastBar = values[values.length - 1];
                                        console.log("lastBar=", JSON.parse(JSON.stringify(lastBar)));

                                        console.log("newBar.date=", moment(newBar.date).format("YYYYMMDD"), " lastBar.date=", lastBar.date);

                                        console.log("Inserting new bar.");
                                        parsedData.values[moment(newBar.date).format("YYYYMMDD")] = newBar;
                                    }
                                    that.parsedData = parsedData;
                                });*/
                                that.parsedData = parsedData;
                            },
                            fail: function (data) {
                                that.$store.commit('decrementNumberOfAjaxCalls');
                                console.log("ajax call failed.");
                                $("#progress").hide();
                                $("#button").show();
                                console.log(data);
                            }
                        });
                    }
                }
            }
        }
    };

</script>
